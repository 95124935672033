import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_luxury_65ft from'../assets/images/yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental/65ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_luxury_65ft_hall from'../assets/images/yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental/65ft-yacht-luxury-hall.webp'
import yacht_luxury_65ft_bedroom from'../assets/images/yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental/65ft-yacht-luxury-bedroom.webp';
import yacht_luxury_65ft_cabin from'../assets/images/yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental/65ft-yacht-captain-cabin.webp';
import yacht_luxury_65ft_2nd_bedroom from'../assets/images/yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental/65ft-yacht-second-bedroom.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';


function YachtLuxury65ft() {
  const hoverImages = [
    { src: yacht_luxury_65ft, alt: "65ft yacht in dubai" },
    { src: yacht_luxury_65ft_bedroom, alt: "65ft yacht bedroom in dubai" },
    { src: yacht_luxury_65ft_hall, alt: "65ft yacht hall in dubai" },
    { src: yacht_luxury_65ft_2nd_bedroom, alt: "65ft yacht luxury bedroom in dubai" },
    { src: yacht_luxury_65ft_cabin, alt: "65ft yacht captain cabin in dubai" },
  ];

    return (

<div>

<Helmet>

<title>65Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 65ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 29 (Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 65ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_luxury_65ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_luxury_65ft",
          "url": "https://dubaiyachtevents.com/yacht_luxury_65ft",
          "name": "65Ft Yacht for Rent - Book Online",
          "description": "Discover the best 65ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 29 Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "65Ft Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 65ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 29 Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT0011",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "699.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_luxury_65ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "16598"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/65ft-yacht-dubai-dubai-marina-yacht-rental.8eada507561ec4a5dfb5.webp",
            "https://dubaiyachtevents.com/static/media/65ft-yacht-luxury-bedroom.7f3a6078f99f04a54c76.webp",
            "https://dubaiyachtevents.com/static/media/65ft-yacht-luxury-hall.6351abba11fe9df4c5d2.webp",
            "https://dubaiyachtevents.com/static/media/65ft-yacht-second-bedroom.55e5cd89abc756d6d816.webp",
            "https://dubaiyachtevents.com/static/media/65ft-yacht-captain-cabin.0f4fb5147f8760ef0abd.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 65ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 65ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 65ft yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 65ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 25 person can travel per ride in 65 ft yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 65ft yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 65ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 65ft yacht in dubai is approximately AED 699"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "699 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
    <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht rental",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "65ft yacht",
        "item": "https://dubaiyachtevents.com/yacht_luxury_65ft"
      }
    ]
  })}
  </script>


     
     </Helmet>
<Header/>

<ScrollArrows/>

    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_luxury_65ft} 
                     mainAlt="65ft yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">65Ft Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 749</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(5489 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                            <li>1. <b>Crew:</b>  Our crew and captain are highly skilled and experienced, ensuring a safe journey.</li>
                            <li>2. <b>Entertainment:</b>  We offer no of  games for children and a DJ music system onboard the yacht for Elders</li>
                            <li>3. <b>Fishing:</b>  Fishing tools are provided for you to enjoy fishing with your family and friends.</li>
                            <li>4. <b>Swimming:</b>  Swimming guys are welcome to swim at no extra cost.</li>
                            <li>5. <b>Starters:</b>  Cool Drinks will be provided to all passengers.</li>
                            <li>6. <b>Safety:</b>  Enjoy your journey with high privacy by our team</li>
                            <li>7. <b>Food:</b>  You can order your favorite meals at any time during your trip. Enjoy your dining experience!</li>
                        
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2065ft%20Aqua%20Breeze%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2065ft%20Aqua%20Breeze%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. The maximum number of 25 peoples can travel per Ride in this yacht</p>
                <p style={{marginTop: '2%'}}>2. Pay once and choose your convenient timing</p>
                <img className="desc-img" src={yacht_luxury_65ft_bedroom} alt="6ft yacht bedroom in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_luxury_65ft_bedroom} alt="6ft yacht bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. Stay Cool with free cool drinks</p>
                <img className="desc-img" src={yacht_luxury_65ft_2nd_bedroom} alt="6ft yacht luxury bedroom in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_luxury_65ft_2nd_bedroom} alt="6ft yacht luxury bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>4. We provide free cancellation only for customers who book online</p>
                <p style={{marginTop: '2%'}}>5. Full Ac rooms in the yacht, high-quality DJ sound system, and high-speed WiFi. Watch movies with your family and friends, enjoy colorful LED lighting, and more.</p>
                <p style={{marginTop: '2%'}}>6. This yacht comes with a mega bedroom where the whole group can rest </p>
                <img className="desc-img" src={yacht_luxury_65ft_hall} alt="6ft yacht hall in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_luxury_65ft_hall} alt="6ft yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>7. This yacht is for those who love premium experiences and want to feel the luxury for sure.</p>
                <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>

                <img src="" alt=""/>



            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


<div/></div>
);
}

export default YachtLuxury65ft;