import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import { Link } from 'react-router-dom';
import Footer from '../Footer';


function DubaiYachtPrices() {
  return (

<div >

<Helmet>
<title>Dubai Yacht Rental Price - Book Online</title>
<meta name="description" content="Check out our Dubai yacht rental price and book online for the best discounts and offers. Enjoy luxury yacht tours with 24/7 service and exclusive offers."/>
<meta name="keywords" content="dubai yacht events prices, dubai yacht rental prices, yacht rental dubai prices, yacht prices in dubai, yacht ride prices dubai, dubai yacht price, dubai yacht ride price" />
<link rel="canonical" href="https://dubaiyachtevents.com/dubai_yacht_prices"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht price",
        "item": "https://dubaiyachtevents.com/dubai_yacht_prices"
      },
    ]
  })}
  </script>


     
     </Helmet>

<Header/>

<ScrollArrows />

  <section class="pricing-table" >
    <h1><strong>Dubai yacht rental prices</strong></h1>
    <div class="pricing-cards">
        <div class="pricing-card">
            <h2><Link class="price-head" to="/boat_rental_dubai">Boat</Link></h2>
            <h4>Staring From</h4>
            <p class="price">AED 379<span>/hour</span></p>
            <ul>
                <li>From 10 people</li>
                <li>Full-day rental available</li>
                <li>Captain Included</li>
                <li>Cool Drinks</li>
                <li>Basic amenities included</li>
            </ul>
            <Link to="#" class="btn-price">Book</Link>
        </div>
        <div class="pricing-card featured">
            <h2><Link to="/yacht_rental" style={{color: 'white'}}>Yacht</Link></h2>
            <h4>Staring From</h4>
            <p class="price">AED 449<span>/hour</span></p>
            <ul>
                <li>From 12 people</li>
                <li>Full-day rental</li>
                <li>Life Jacket included</li>
                <li>Captain and crew included</li>
                <li>Luxury amenities included</li>
                <li>Soft & Cold Drinks included</li>
                <li>Complimentary beverages</li>
                <li>Professional instructor</li>
                <li>Fuel included</li>


            </ul>
            <Link to="#" class="btn-price">Book</Link>
        </div>
        <div class="pricing-card">
            <h2><Link class="price-head" to="/#location">WaterSports</Link></h2>
            <h4>Staring From</h4>
            <p class="price">AED 199<span>/hour</span></p>
            <ul>
                <li>Per-hour rental</li>
                <li>Life Jacket included</li>
                <li>Water sports equipment</li>
                <li>Professional instructor</li>
            </ul>
            <Link to="#" class="btn-price">Book</Link>
        </div>
    </div>
</section>



  <Footer/>

  
 

</div>

);
}

export default DubaiYachtPrices;