import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import { Link } from 'react-router-dom';
import Footer from '../Footer';


function DubaiYachtTourPrice() {
  return (
<div >

<Helmet>
<title>Dubai Yacht Tour Price - Book Online</title>
<meta name="description" content="Check out our Dubai yacht tour price and book online for the best deals. Enjoy luxury yacht tours with 24/7 service and exclusive offers."/>
<meta name="keywords" content="dubai yacht events prices, dubai yacht prices, yacht dubai prices, yacht prices in dubai, yacht ride prices dubai, dubai yacht price, dubai yacht ride price" />
<link rel="canonical" href="https://dubaiyachtevents.com/dubai_yacht_tour_price"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "tour price",
        "item": "https://dubaiyachtevents.com/dubai_yacht_tour_price"
      }
    ]
  })}
  </script>

     
     </Helmet>

<Header/>

<ScrollArrows />

  <section className="pricing-table" >
    <h1><strong>Dubai yacht tour prices</strong></h1>
    <div className="table-container">
        <table className="styled-table">
            <thead>
                <tr>
                    <th><h1>Yachts for Sale</h1></th>
                    <th><h1>Yacht Tour Price</h1></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Link to="/yacht42ft"><strong>42Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht42ft"><strong>449 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht44ft"><strong>44Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht44ft"><strong>499 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht45ft"><strong>45Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht45ft"><strong>499 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_azimut_55ft"><strong>55Ft Azimut Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_azimut_55ft"><strong>549 AED(Dirhams)/14 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_55ft"><strong>55Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_55ft"><strong>599 AED(Dirhams)/22 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_56ft"><strong>56Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_56ft"><strong>699 AED(Dirhams)/20 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_65ft"><strong>65Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_65ft"><strong>749 AED(Dirhams)/25 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_68ft"><strong>68Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_68ft"><strong>799 AED(Dirhams)/25 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_75ft"><strong>75Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_75ft"><strong>949 AED(Dirhams)/30 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_80ft"><strong>80Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_80ft"><strong>1199 AED(Dirhams)/35 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_80ft"><strong>80Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_80ft"><strong>1199 AED(Dirhams)/35 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_88ft"><strong>88Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_88ft"><strong>1899 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_90ft"><strong>90Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_90ft"><strong>1499 AED(Dirhams)/65 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_90ft"><strong>90Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_90ft"><strong>1499 AED(Dirhams)/40 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_vip_90ft"><strong>90Ft Luxury Vip Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_vip_90ft"><strong>2399 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_95ft"><strong>95Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_95ft"><strong>1999 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_family_100ft"><strong>100Ft Family Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_family_100ft"><strong>2699 AED(Dirhams)/80 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_premium_luxury_101ft"><strong>101Ft Premium Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_premium_luxury_101ft"><strong>2499 AED(Dirhams)/65 Guest</strong></Link></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>



  <Footer/>

  
 
</div>


);
}

export default DubaiYachtTourPrice;
