import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_42ft_full_view from'../assets/images/yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental/42ft-yacht-full-view.webp';
import yacht_42ft_bedroom_2 from'../assets/images/yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental/42ft-yacht-bedroom-2.webp';
import yacht_42ft_hall from'../assets/images/yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental/42ft-yacht-hall.webp';
import yacht_42ft_bedroom from'../assets/images/yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental/42ft-yacht-bedroom.webp';
import yacht_42ft_washbasin from'../assets/images/yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental/42ft-yacht-washbasin.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function Yacht42ft() {

    const hoverImages = [
        { src: yacht_42ft_full_view, alt: "42ft gulf yacht full view in dubai" },
        { src: yacht_42ft_bedroom_2, alt: "42ft gulf yacht bedroom with double bed in dubai" },
        { src: yacht_42ft_hall, alt: "42ft gulf yacht hall in dubai" },
        { src: yacht_42ft_bedroom, alt: "42ft gulf yacht bedroom in dubai" },
        { src: yacht_42ft_washbasin, alt: "42ft gulf yacht washbasin in dubai" },
      ];

    return (
      <div>

<Helmet>

<title>42Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 37 Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 42ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht42ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "WebPage",
      "@id": "https://dubaiyachtevents.com/yacht42ft",
      "url": "https://dubaiyachtevents.com/yacht42ft",
      "name": "42Ft Gulf Yacht in Dubai Marina @349 AED",
      "description": "Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 37 Dirhams) per person including meals. Book online and get an extra discount",
      "publisher": {
        "@type": "Organization",
        "name": "Dubai Yacht Events",
        "logo": {
          "@type": "ImageObject",
          "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
        },
        "url": "https://dubaiyachtevents.com/",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+971545619773",
          "contactType": "Customer Service",
          "areaServed": ["EN", "AE", "HI", "ML", "TA"]
        }
      }
    },
    {
      "@type": "WebSite",
      "name": "Dubai Yacht Events",
      "alternateName": "Dubai Yacht Ride",
      "url": "https://dubaiyachtevents.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    },
    {
      "@type": "Product",
      "name": "42Ft Dubai Yacht-Dubai Marina Yacht Rental",
      "description": "Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 37 Dirhams) per person including meals. Book online and get an extra discount",
      "sku": "YACHT005",
      "brand": {
        "@type": "Brand",
        "name": "Dubai Yacht Events"
      },
      "offers": {
        "@type": "Offer",
        "price": "349.00",
        "priceCurrency": "AED",
        "priceValidUntil": "2024-12-31",
        "availability": "https://schema.org/InStock",
        "url": "https://dubaiyachtevents.com/yacht42ft",
        "seller": {
          "@type": "Organization",
          "name": "Dubai Yacht Events"
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "itemReviewed": {
          "@type": "Service",
          "name": "Boat & Yacht Rental Dubai"
        },
        "ratingValue": "4.9",
        "reviewCount": "8549"
      },
      "image": [
        "https://dubaiyachtevents.com/static/media/42ft-yacht-full-view.1f915a91b58b0e36eab0.webp",
        "https://dubaiyachtevents.com/static/media/42ft-yacht-bedroom-2.857baf0512a0949efe38.webp",
        "https://dubaiyachtevents.com/static/media/42ft-yacht-hall.14809a5de8b48373e6f8.webp",
        "https://dubaiyachtevents.com/static/media/42ft-yacht-bedroom.de98d955c0e34816bc9b.webp",
        "https://dubaiyachtevents.com/static/media/42ft-yacht-washbasin.b8b760cb808fc71cff3e.webp"
      ]
    },
    {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "How to rent 42ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Contact our support (dubaiyachtevents.com) via call or WhatsApp, then confirm the price and book your slot."
          }
        },
        {
          "@type": "Question",
          "name": "Where can I ride this 42 ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "This 42ft yacht is available in Dubai Marina. You can start your ride from Dubai Marina."
          }
        },
        {
          "@type": "Question",
          "name": "How many people can travel in 42ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Maximum 12 people can travel per ride in the 42 ft yacht in Dubai."
          }
        },
        {
          "@type": "Question",
          "name": "How to book 42ft yacht in Dubai Marina?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Dubaiyachtevents.com is the best website to book a yacht in Dubai with 0% commission."
          }
        },
        {
          "@type": "Question",
          "name": "What is the price for the 42ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The price for the 42ft yacht in Dubai is approximately AED 349."
          }
        }
      ]
    },
    {
      "@type": "Place",
      "name": "Dubai Marina",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      }
    },
    {
      "@type": "LocalBusiness",
      "name": "Dubai Yacht Events",
      "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      },
      "url": "https://dubaiyachtevents.com/",
      "telephone": "+971545619773",
      "priceRange": "349 AED",
      "sameAs": [
        "https://m.facebook.com/vcyachtsdubai/",
        "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
        "https://pin.it/2CuYXWb",
        "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
        "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
        "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
        "https://boatrentaldubaimarina.blogspot.com/"
      ],
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        }
      ]
    },
    {
      "@type": "Review",
      "itemReviewed": {
        "@type": "LocalBusiness",
        "name": "Dubai Yacht Events"
      },
      "author": {
        "@type": "Person",
        "name": "Ravi kumar"
      },
      "datePublished": "2024-06-25",
      "reviewBody": "This gulf yacht is super and luxurious. I did not expect this from this budget.",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      }
    }
  ]
})}
</script>


<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht rental",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "42ft yacht",
        "item": "https://dubaiyachtevents.com/yacht42ft"
      }
    ]
  })}
  </script>





     
     </Helmet>

      <Header/>

      <ScrollArrows/>

      
          <div className = "main-wrapper">
              <div className = "container">
                  <div className = "product-div">
                      <div className = "product-div-left">
                      <YachtImageShowcase 
                     mainImage={yacht_42ft_full_view} 
                     mainAlt="42ft gulf yacht full view in dubai" 
                     hoverImages={hoverImages} 
                   />
                      </div>
                      <div className = "product-div-right">
                          <span className = "product-name">42Ft Dubai Yacht-Dubai Marina Yacht Rental</span>
                          <span className = "product-price">AED 449</span>
                          <div className = "product-rating">
                              <span><i className = "fas fa-star"></i></span>
                              <span><i className = "fas fa-star"></i></span>
                              <span><i className = "fas fa-star"></i></span>
                              <span><i className = "fas fa-star"></i></span>
                              <span><i className = "fas fa-star-half-alt"></i></span>
                              <span>(1058 ratings)</span>
                          </div>
                          <p className = "product-description"><ul>
                              <li>1. <b>Crew:</b> Experienced captain and crew on the yacht</li>
                              <li>2. <b>Entertainment:</b> Music system available on the yacht</li>
                              <li>3. <b>Safety:</b> Life jackets for all passengers</li>
                              <li>4. <b>Fishing:</b> Provided fishing equipment</li>
                              <li>5. <b>Swimming:</b> Swimming opportunities available</li>
                              <li>6. <b>Starters:</b>  Complimentary water and soft drinks</li>
                          </ul></p>
                          <div className="btn-groups">
                              <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2042ft%20Gulf%20Glimmer%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                              <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2042ft%20Gulf%20Glimmer%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                          </div>
                          <div className = "social-links">
                            <p>Share At: </p>
                            <Link to = "#">
                              <i className = "fab fa-facebook-f"></i>
                            </Link>
                            <Link to = "#">
                              <i className = "fab fa-twitter"></i>
                            </Link>
                            <Link to = "#">
                              <i className = "fab fa-instagram"></i>
                            </Link>
                            <Link to = "#">
                              <i className = "fab fa-whatsapp"></i>
                            </Link>
                            <Link to = "#">
                              <i className = "fab fa-pinterest"></i>
                            </Link>
                          </div>
                          
                          
                          
                      </div>
                      <div style={{marginLeft: '10%' , marginRight: '10%'}}>
                        <h1>Description</h1><br/>
                        <p style={{marginTop: '2%'}}>1. It is a budget-friendly yacht for all peoples who can afford it. <strong>Small yacht rental dubai</strong> It is designed for those who want to ride a yacht once in their life and can easily afford it.</p>
                        <p style={{marginTop: '2%'}}>2. This yacht comes with 2 bedrooms with soft mattresses offering a very neat and luxurious experience, where you can enjoy time with your partner and family.</p>
                        <img className="desc-img" src={yacht_42ft_bedroom} alt="42ft gulf yacht bedroom in dubai" onclick="openModal('modal1')"/>
                    
                        <div id="modal1" className="modal">
                            <div className="modal-content">
                                <span className="close" onclick="closeModal('modal1')">&times;</span>
                                <img src={yacht_42ft_bedroom} alt="42ft gulf yacht bedroom in dubai" style={{width: '100%'}}/>
                            </div>
                        </div>
                    
                        <p style={{marginTop: '2%'}}>3. It comes with a big dining hall attached to the kitchen, with a stove included. If you are looking for a company meeting, anniversary party, or birthday party, this dining hall gives the best experience.</p>
                        <img className="desc-img" src={yacht_42ft_hall} alt="42ft gulf yacht hall in dubai" onclick="openModal('modal2')"/>
                    
                        <div id="modal2" className="modal">
                            <div className="modal-content">
                                <span className="close" onclick="closeModal('modal2')">&times;</span>
                                <img src={yacht_42ft_hall} alt="42ft gulf yacht hall in dubai" style={{width: '100%'}}/>
                            </div>
                        </div>
                    
                        <p style={{marginTop: '2%'}}>4. It is a high-demand yacht because most people love budget-friendly options.</p>
                        <p style={{marginTop: '2%'}}>5. It is fully equipped with AC, sound system, high-speed WiFi, LED lighting, etc.</p>
                        <p style={{marginTop: '2%'}}>6. A super-soft cushion sofa is kept in the dining hall, giving you the best comfort.</p>
                        <img className="desc-img" src={yacht_42ft_washbasin} alt="42ft gulf yacht washbasin in dubai" onclick="openModal('modal3')"/>
                    
                        <div id="modal3" className="modal">
                            <div className="modal-content">
                                <span className="close" onclick="closeModal('modal3')">&times;</span>
                                <img src={yacht_42ft_washbasin} alt="42ft gulf yacht washbasin in dubai" style={{width: '100%'}}/>
                            </div>
                        </div>
                    
                        <p style={{marginTop: '2%'}}>7. When it comes to safety, we provide life jackets for all passengers. Safety comes first. Enjoy your ride and happy riding!</p>
                        <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>
                    </div>
              </div>
                  
              </div>
              
          </div>
          
      
          <Footer/>
      
       
      </div>
    );
}

export default Yacht42ft;
