import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavbarMenu = () => {
  useEffect(() => {
    const overlay = document.querySelector("[data-overlay]");
    const navbar = document.querySelector("[data-navbar]");
    const navToggleBtn = document.querySelector("[data-nav-toggle-btn]");
    const navbarLinks = document.querySelectorAll("[data-nav-link]");

    const navToggleFunc = () => {
      navToggleBtn.classList.toggle("active");
      navbar.classList.toggle("active");
      overlay.classList.toggle("active");
    }

    navToggleBtn.addEventListener("click", navToggleFunc);
    overlay.addEventListener("click", navToggleFunc);

    navbarLinks.forEach(link => {
      link.addEventListener("click", navToggleFunc);
    });

    // Cleanup event listeners on unmount
    return () => {
      navToggleBtn.removeEventListener("click", navToggleFunc);
      overlay.removeEventListener("click", navToggleFunc);
      navbarLinks.forEach(link => {
        link.removeEventListener("click", navToggleFunc);
      });
    }
  }, []);

  return (
    <nav className="navbar" data-navbar>
      <ul className="navbar-list">
        <li>
          <Link title="Return to homepage" to="/" className="navbar-link" data-nav-link>Home</Link>
        </li>
        <li>
          <Link title="Move to yachts section" to="/yacht_rental" className="navbar-link" data-nav-link>Explore Yacht</Link>
        </li>
        <li>
          <Link title="Move to boats section" to="/boat_rental_dubai" className="navbar-link" data-nav-link>Explore Boat</Link>
        </li>
        <li>
          <HashLink  title="Move to water sports section" to="/#location" className="navbar-link" data-nav-link>Water Sports</HashLink >
        </li>
        <li>
          <HashLink  title="Move to contact section" to="/contact_us" className="navbar-link" data-nav-link>Contact</HashLink >
        </li>
      </ul>
    </nav>
  );
};

export default NavbarMenu;
