import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_45ft from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_45ft_bedroom from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45ft-yacht-bed-room.webp'
import yacht_45ft_private_bed from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45ft-yacht-private-bed.webp';
import yacht_45ft_rest_room from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45-ft-yacht-rest-room.webp';
import yacht_45ft_dining from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45ft-yacht-dining-room.webp';
import yacht_45ft_washbasin from'../assets/images/yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental/45ft-yacht-glassy-washbasin.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function Yacht45ft() {
  const hoverImages = [
    { src: yacht_45ft, alt: "45ft gulf yacht in dubai " },
    { src: yacht_45ft_bedroom, alt: "45ft gulf yacht bedroom in dubai" },
    { src: yacht_45ft_private_bed, alt: "45ft gulf yacht in private bed in dubai" },
    { src: yacht_45ft_rest_room, alt: "45ft gulf yacht in restroom in dubai" },
    { src: yacht_45ft_dining, alt: "45ft gulf yacht dining room in dubai" },
  ];

    return (
    
   
  <div>

<Helmet>

<title>45Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 45ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 45ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht45ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "WebPage",
      "@id": "https://dubaiyachtevents.com/yacht45ft",
      "url": "https://dubaiyachtevents.com/yacht45ft",
      "name": "45Ft Yacht for Rent - Book Online",
      "description": "Discover the best 45ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount",
      "publisher": {
        "@type": "Organization",
        "name": "Dubai Yacht Events",
        "logo": {
          "@type": "ImageObject",
          "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
        },
        "url": "https://dubaiyachtevents.com/",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+971545619773",
          "contactType": "Customer Service",
          "areaServed": ["EN", "AE", "HI", "ML", "TA"]
        }
      }
    },
    {
      "@type": "WebSite",
      "name": "Dubai Yacht Events",
      "alternateName": "Dubai Yacht Ride",
      "url": "https://dubaiyachtevents.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    },
    {
      "@type": "Product",
      "name": "45Ft Dubai Yacht-Dubai Marina Yacht Rental",
      "description": "Discover the best 45ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount",
      "sku": "YACHT007",
      "brand": {
        "@type": "Brand",
        "name": "Dubai Yacht Events"
      },
      "offers": {
        "@type": "Offer",
        "price": "399.00",
        "priceCurrency": "AED",
        "priceValidUntil": "2024-12-31",
        "availability": "https://schema.org/InStock",
        "url": "https://dubaiyachtevents.com/yacht45ft",
        "seller": {
          "@type": "Organization",
          "name": "Dubai Yacht Events"
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "54987"
      },
      "image": [
        "https://dubaiyachtevents.com/static/media/45ft-dubai-yacht-dubai-marina-yacht-rental.c822b5c81e06fc525630.webp",
        "https://dubaiyachtevents.com/static/media/45ft-yacht-bed-room.f06ec7a2c30b5ba82cf5.webp",
        "https://dubaiyachtevents.com/static/media/45ft-yacht-private-bed.d8386d26190e9fecd558.webp",
        "https://dubaiyachtevents.com/static/media/45-ft-yacht-rest-room.e13ffc4aebb4a2176c38.webp",
        "https://dubaiyachtevents.com/static/media/45ft-yacht-dining-room.bda5fd8483a8a9513a56.webp"
      ]
    },
    {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "How to rent 45ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Contact our support(dubaiyachtevents.com) via call or WhatsApp, then confirm the price and book your slot."
          }
        },
        {
          "@type": "Question",
          "name": "Where can I ride this 45 ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "This 45ft yacht is available in Dubai Marina. You can start your ride from Dubai Marina."
          }
        },
        {
          "@type": "Question",
          "name": "How many people can travel in 45ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Maximum 12 people can travel per ride in the 45 ft yacht in Dubai."
          }
        },
        {
          "@type": "Question",
          "name": "How to book 45ft yacht in Dubai Marina?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
          }
        },
        {
          "@type": "Question",
          "name": "What is the price for the 45ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The price for the 45ft yacht in Dubai is approximately AED 399."
          }
        }
      ]
    },
    {
      "@type": "Place",
      "name": "Dubai Marina",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      }
    },
    {
      "@type": "LocalBusiness",
      "name": "Dubai Yacht Events",
      "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      },
      "url": "https://dubaiyachtevents.com/",
      "telephone": "+971545619773",
      "priceRange": "399 AED",
      "sameAs": [
        "https://m.facebook.com/vcyachtsdubai/",
        "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
        "https://pin.it/2CuYXWb",
        "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
        "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
        "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
        "https://boatrentaldubaimarina.blogspot.com/"
      ],
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        }
      ]
    }
  ]
})}
</script>

<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht rental",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "45ft yacht",
        "item": "https://dubaiyachtevents.com/yacht45ft"
      }
    ]
  })}
  </script>



     
     </Helmet>
  <Header/>

  <ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_45ft} 
                     mainAlt="45ft gulf yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">45Ft Dubai Yacht-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 499</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(1258 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                        <li>1. <b>Crew:</b> An experienced captain and friendly crew are on the yacht. They guide you to get the best experience.</li>
                        <li>2. <b>Entertainment:</b> A music system is available on the yacht. Let's enjoy the party with a DJ.</li>     
                        <li>3. <b>Fishing Gear:</b> Fishing equipment is provided. Catch the biggest fish by challenging your friends.</li>
                        <li>4. <b>Swimming:</b> Swimming opportunities are available. Discover how deep the sea is with the colorful fish.</li>
                        <li>5. <b>Starters:</b>  Starter drinks are included with the yacht.</li>
                        <li>6. <b>Safety Gear:</b>Life jackets for all passengers. Safety is the top priority in Dubai, and we provide it.</li>
                        <li>7. <b>Food:</b>  Order your favorite food at an extra cost. Enjoy live BBQ at an additional charge.</li>
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2045ft%20Gulf%20Glide%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2045ft%20Gulf%20Glide%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{ marginLeft: '10%', marginRight: '10%' }}><h1 >Description</h1><br />
                <p style={{marginTop: '2%'}} >1. A white yacht that is neat and clean. This is a faster and sharper-designed yacht. The white color gives you a peaceful feeling.</p>
                <p style={{marginTop: '2%'}} >2. This yacht comes with a clean bed and sleeps up to 6 people with a very soft mattress, providing a neat and luxurious experience. Take a deep breathe</p>
                <img className="desc-img" src={yacht_45ft_bedroom} alt="45ft gulf yacht in bedroom" onclick="openModal('modal1')" />
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_45ft_bedroom} alt="45ft gulf yacht in bedroom" style={{width: '100%;'}} />
                      </div>
                  </div>
                <p style={{marginTop: '2%'}} >3. This dining area is designed purely for family entertainment. Let's celebrate parties like birthdays, wedding anniversaries, etc., with family.</p>
                <p style={{marginTop: '2%'}} >4. This yacht has a sofa on the front side so that you can enjoy and feel the real speed.</p>
                <img className="desc-img" src={yacht_45ft_washbasin} alt="45ft gulf yacht washbasin in dubai" onclick="openModal('modal2')" />
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_45ft_washbasin} alt="45ft gulf yacht washbasin in dubai" style={{width: '100%;'}} />
                      </div>
                  </div>
                <p style={{marginTop: '2%'}} >5. It is fully covered with Air cooler , DJ sound system and high speed wifi,LED lightings etc.</p>
                <p style={{marginTop: '2%'}} >6. There are multiple sofas in various areas. Choose your seating based on your preferred view.</p>
                <img className="desc-img" src={yacht_45ft_private_bed} alt="45ft gulf yacht private bed in dubai" onclick="openModal('modal3')" />
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_45ft_private_bed} alt="45ft gulf yacht private bed in dubai" style={{width: '100%;'}} />
                      </div>
                  </div>
                <p style={{marginTop: '2%'}} >7. We provide the best safety and privacy in terms of competitors </p>
                <p style={{marginTop: '2%'}} >8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>

            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


</div>

);
}

export default Yacht45ft;
