import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/boat_styles.css';
import { Link } from 'react-router-dom';
import Header from '../Header';
import favicon from '../assets/images/favicon.webp';
import ScrollArrows from '../ScrollArrows';
import speed_boat from'../boat_assets/images/speed-boat-dubai-dubai-marina-boat-rental/speed-boat-dubai-dubai-marina-boat-rental.webp';
import speed_boat_bedroom from'../boat_assets/images/speed-boat-dubai-dubai-marina-boat-rental/speedy-dreamy-drifter-bedroom.webp';
import speed_boat_front_view from'../boat_assets/images/speed-boat-dubai-dubai-marina-boat-rental/speedy-dreamy-drifter-frontview.webp';
import speed_boat_night_view from'../boat_assets/images/speed-boat-dubai-dubai-marina-boat-rental/speedy-dreamy-drifter-night-view.webp';
import speed_boat_side_view from'../boat_assets/images/speed-boat-dubai-dubai-marina-boat-rental/speedy-dreamy-drifter-sideview.webp';
import BoatImgShowcase from './BoatImageShowcase';
import Footer from '../Footer';



function SpeedBoat() {
    return (
    
        <div className='body'>

     <Helmet>

     <title>Speed Drifter Boat in Dubai Marina @299 AED</title>
     <meta name="description" content="Check out the Speed boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!"/>
     <meta name="keywords" content="book dubai boat, speed boat dubai, mini boat dubai, small boat dubai, dubai marina boat, boat rental dubai, dubai boat ride" />
     <link rel="canonical" href="https://dubaiyachtevents.com/speed_boat"/>
     <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebPage",
            "@id": "https://dubaiyachtevents.com/speed_boat",
            "url": "https://dubaiyachtevents.com/speed_boat",
            "name": "Speed Drifter Boat in Dubai Marina @299 AED",
            "description": "Check out the Speed Drifter boat in Dubai Marina, now including a 10% Instant discount and save upto 50%. Book now to get the discounted price!",
            "publisher": {
              "@type": "Organization",
              "name": "Dubai Yacht Events",
              "logo": {
                "@type": "ImageObject",
                "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
              },
              "url": "https://dubaiyachtevents.com/",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+971545619773",
                "contactType": "Customer Service",
                "areaServed": ["EN", "AE", "HI", "ML", "TA"]
              }
            }
          },
          {
            "@type": "WebSite",
            "name": "Dubai Yacht Events",
            "alternateName": "Dubai Yacht Ride",
            "url": "https://dubaiyachtevents.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
              },
              "query-input": "required name=search_term_string"
            }
          },
          {
            "@type": "Product",
            "name": "Speed Boat Dubai-Dubai Marina Boat Rental",
            "description": "Check out the Speed Drifter boat in Dubai Marina, now including a 10% Instant discount and save upto 50%. Book now to get the discounted price!",
            "sku": "YACHT004",
            "brand": {
              "@type": "Brand",
              "name": "Dubai Yacht Events"
            },
            "offers": {
              "@type": "Offer",
              "price": "299.00",
              "priceCurrency": "AED",
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/InStock",
              "url": "https://dubaiyachtevents.com/speed_boat",
              "seller": {
                "@type": "Organization",
                "name": "Dubai Yacht Events"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "reviewCount": "5498"
            },
            "image": [
              "https://dubaiyachtevents.com/static/media/speed-boat-dubai-dubai-marina-boat-rental.d50f29f4fd2edba7148c.webp",
              "https://dubaiyachtevents.com/static/media/speedy-dreamy-drifter-bedroom.7675ae31c9f6913beff4.webp",
              "https://dubaiyachtevents.com/static/media/speedy-dreamy-drifter-frontview.3c5b07b6f77612c6acf0.webp",
              "https://dubaiyachtevents.com/static/media/speedy-dreamy-drifter-night-view.e5e68f939f7b63ec2ce3.webp",
              "https://dubaiyachtevents.com/static/media/speedy-dreamy-drifter-sideview.f7f98c40a4bd4c5bf46e.webp"
            ]
          },
          {
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "How to rent speed drifter boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
                }
              },
              {
                "@type": "Question",
                "name": "Where i can ride this speed drifter boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "This speed drifter boat is available in dubai marina.You can start your ride from dubai marina."
                }
              },
              {
                "@type": "Question",
                "name": "how many person can travel in speed drifter boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Maximum 10 person can travel per ride in speed drifter boat in dubai"
                }
              },
              {
                "@type": "Question",
                "name": "How to book speed drifter  boat in dubai marina?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "dubaiyachtevents.com is the best website to book speed drifter boat in Dubai with 0% commission."
                }
              },
              {
                "@type": "Question",
                "name": "What is the price for the speed drifter boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The price for the speed drifter boat in dubai is approximately AED 399"
                }
              }
            ]
          },
          {
            "@type": "Place",
            "name": "Dubai Marina",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            }
          },
          {
            "@type": "LocalBusiness",
            "name": "Dubai Yacht Events",
            "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            },
            "url": "https://dubaiyachtevents.com/",
            "telephone": "+971545619773",
            "priceRange": "399 AED",
            "sameAs": [
            "https://m.facebook.com/vcyachtsdubai/",
            "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
            "https://pin.it/2CuYXWb",
            "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
            "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
            "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
            "https://boatrentaldubaimarina.blogspot.com/"
            ],
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              }
            ]
          }
        ]
      })}
      </script>
  <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "speed boat",
        "item": "https://dubaiyachtevents.com/speed_boat"
      }
    ]
  })}
  </script>


     
     </Helmet>

        <Header/>

        <ScrollArrows />
      
        
        <div className = "boat-card-wrapper">
          <div className = "boat-card">
          <BoatImgShowcase
              boat_1={speed_boat}
              boat_2={speed_boat_bedroom}
              boat_3={speed_boat_front_view}
              boat_4={speed_boat_night_view}
              boat_5={speed_boat_side_view}
              boat_1_alt="speed boat in dubai"
              boat_2_alt="speed boat bedroom in dubai"
              boat_3_alt="speed boat frontview in dubai"
              boat_4_alt="speed boat night view in dubai"
              boat_5_alt="speed boat side view in dubai"
            />
            <div className = "boat-product-content">
              <h2 className = "boat-product-title">Speed Boat Dubai</h2>
              <Link to = "#" className = "boat-product-link">Visit Dubai</Link>
              <div className = "boat-product-rating">
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star-half-alt"></i>
                <span>4.8(9354)</span>
              </div>
    
              <div className = "boat-product-price">
                <p className = "boat-last-price">Old Price: <span>AED 429.00</span></p>
                <p className = "boat-new-price">New Price: <span><b>AED 399.00 (5%)</b></span></p>
              </div>
    
              <div className = "boat-product-detail">
                <h2>About This Boat: </h2>
                <p>The Speed Boat Dubai-Dubai Marina Boat Rental is specially designed for speed lovers. The motor is designed for speed, and its low weight makes it easier to achieve high speeds.</p>
                <ul>
                  <li>Name: <span><b>Speed Boat Dubai-Dubai Marina Boat Rental</b></span></li>
                  <li>Hours: <span><b>Flexible Hours</b></span></li>
                  <li>Category: <span><b>Dubai Boat</b></span></li>
                  <li>Location: <span><b>Dubai Marina</b></span></li>
                  <li>PLaces: <span><b>Al over Dubai</b></span></li>
                  <li>Includes: <span><b>Juice,Fishing Equipments etc</b></span></li>
                  <li>Add ons: <span><b>Food,Watersports etc</b></span></li>
                </ul>
              </div>
    
              <div className = "boat-purchase-info">
                
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%20Speedy%20Dreamy%20Drifter.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">
                  Watsapp
                </Link>
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%20Speedy%20Dreamy%20Drifter.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">Rent Now</Link>
              </div>
    
              <div className = "boat-social-links">
                <p>Share At: </p>
                <Link to = "#">
                  <i className = "fab fa-facebook-f"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-twitter"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-instagram"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-whatsapp"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-pinterest"></i>
                </Link>
              </div>
              
            </div>

            <div className='boat-details'>

            <h2>Description</h2><br/>
            <p>The Speed Boat Dubai-Dubai Marina Boat Rental is a racer, drifter, and smooth performer.<br/>
            Maximum <strong>10 peoples</strong> can ride in this boat. <br/>
            We provide high quality life jacket for all passengers.Safety First is our policy <br/>
            For all 10 people, we provide cool drinks and drinks based on your choice <br/>
            All the above features are included with the price.
            Additionally you can buy or order foods at extra cost.
          </p>
          </div>
          </div>
        </div>
    
    
      <Footer/>
    
    
        

      </div>
);

}

export default SpeedBoat;