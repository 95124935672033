import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ScrollArrows from './ScrollArrows';
import HeroForm from './HeroForm';
import Header from './Header';
import AvailabilityCheck from './AvailabilityCheck';
import './assets/css/style.css';
import favicon from './assets/images/favicon.webp'
import yacht_42ft from'./assets/images/dubai-yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_44ft from'./assets/images/dubai-yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_45ft from'./assets/images/dubai-yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_55ft from'./assets/images/dubai-yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_55_1ft from'./assets/images/dubai-yacht-images/55ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_56ft from'./assets/images/dubai-yacht-images/56ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_65ft from'./assets/images/dubai-yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_68ft from'./assets/images/dubai-yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_75ft from'./assets/images/dubai-yacht-images/75ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_80ft from'./assets/images/dubai-yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_80ft from'./assets/images/dubai-yacht-images/80ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_88ft from'./assets/images/dubai-yacht-images/88ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_90ft from'./assets/images/dubai-yacht-images/90ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_90ft from'./assets/images/dubai-yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import vip_luxury_yacht_90ft from'./assets/images/dubai-yacht-images/90ft-luxury-vip-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_95ft from'./assets/images/dubai-yacht-images/95ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import family_yacht_100ft from'./assets/images/dubai-yacht-images/100ft-family-yacht-dubai-dubai-marina-yacht-rental.webp';
import premium_luxury_yacht_101ft from'./assets/images/dubai-yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import boat_33ft from'./assets/images/dubai-boat-images/33ft-boat-dubai-dubai-marina-boat-rental.webp';
import boat_36ft from'./assets/images/dubai-boat-images/36ft-boat-dubai-dubai-marina-boat-rental.webp';
import boat_40ft from'./assets/images/dubai-boat-images/40ft-boat-dubai-dubai-marina-boat-rental.webp';
import speed_boat from'./assets/images/dubai-boat-images/speed-boat-dubai-dubai-marina-boat-rental.webp';
import why_choose_us from'./assets/images/why-choose-us.webp';
import parasailing_ride from'./assets/images/watersports-images/parasailing-ride-dubai.webp';
import donut_ride from'./assets/images/watersports-images/donut-ride-dubai.webp';
import jetski_ride from'./assets/images/watersports-images/jetski-ride-dubai.webp';
import wakeboard_ride from'./assets/images/watersports-images/wakeboard-ride-dubai.webp';
import jetcar_ride from'./assets/images/watersports-images/jetcar-ride-dubai.webp';
import flyboard_ride from'./assets/images/watersports-images/flyboard-ride-dubai.webp';
import banana_ride from'./assets/images/watersports-images/banana-ride-dubai.webp';
import Footer from './Footer';





function Home() {
    return (
        <div>
          <Helmet>

          <title>Best Yacht Rental Dubai - Book Online Cheap Price</title>
          <meta name="description" content="We are the best yacht rental in Dubai at cheap prices. Book online now for the best deals, offers, and discounts. Enjoy 24/7 service and a wide range of yachts"/>
          <meta name="keywords" content="Dubai yacht rental, luxury yacht Dubai, rent yacht Dubai, yacht party Dubai, book dubai yacht, book dubai boat, boat rental dubai, dubai boat ride, dubai marina boat, watersports dubai, dubai waterfun, dubai parasailing, water events dubai, dubai yacht events, yacht rental dubai, boat charter dubai, boat cruises dubai, luxury cruise dubai, yacht rental in dubai, boat trip dubai, sea trip dubai, boat hire dubai, boat hire in dubai, fishing trip dubai, boat rent dubai, boat tour in dubai, yacht charter dubai, dubai sea cruise, private charter dubai, dubai yacht charter, dubai yacht rental, fishing trips dubai, rent boat dubai, boat tour dubai, rent a boat dubai, rent a yacht dubai, sea tour dubai, dubai boat rental, dubai boat rentals, boat rental in dubai, boat hire in dubai, boat rental dubai, boat hire dubai, yacht rental in duabi, boat rentals dubai, yacht charters dubai, boat charter dubai, yacht charters in dubai, rent boat in dubai, rent a boat dubai, rent boat dubai, sea tour dubai, sea trip dubai, yacht charter dubai, yacht charter in dubai, dubai yacht cruise, dubai boat charter, boat hire dubai"/>
          <link rel="canonical" title="" href="https://dubaiyachtevents.com/"/>
          <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
        {JSON.stringify({
           "@context": "https://schema.org",
           "@graph": [
             {
               "@type": "WebPage",
               "@id": "https://dubaiyachtevents.com/",
               "url": "https://dubaiyachtevents.com/",
               "name": "Best Yacht Rental Dubai - Book Online Cheap Price",
               "description": "We are the best yacht rental in Dubai at cheap prices. Book online now for the best deals, offers, and discounts. Enjoy 24/7 service and a wide range of yachts",
               "publisher": {
                 "@type": "Organization",
                 "name": "Dubai Yacht Events",
                 "logo": {
                   "@type": "ImageObject",
                   "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
                 },
                 "url": "https://dubaiyachtevents.com/",
                 "contactPoint": {
                   "@type": "ContactPoint",
                   "telephone": "+971545619773",
                   "contactType": "Customer Service",
                   "areaServed": ["EN", "AE", "HI", "ML", "TA"]
                 }
               }
             },
             {
               "@type": "WebSite",
               "name": "Dubai Yacht Events",
               "alternateName": "Dubai Yacht Ride",
               "url": "https://dubaiyachtevents.com/",
               "potentialAction": {
                 "@type": "SearchAction",
                 "target": {
                   "@type": "EntryPoint",
                   "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
                 },
                 "query-input": "required name=search_term_string"
               }
             },
             {
               "@type": "Service",
               "name": "Dubai Yacht Events",
               "description": "We are the best yacht rental in Dubai at cheap prices. Book online now for the best deals, offers, and discounts. Enjoy 24/7 service and a wide range of yachts",
               "provider": {
                 "@type": "Organization",
                 "name": "Dubai Yacht Events",
                 "url": "https://dubaiyachtevents.com/"
               }
             },
             {
               "@type": "FAQPage",
               "mainEntity": [
                 {
                   "@type": "Question",
                   "name": "How to book a yacht or boat in Dubai?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "Search for the best yacht or boat rental website in Dubai that offers low prices, no hidden fees, good customer support, friendly staff, and free cancellation. Check their background to see how long they have been in this field."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "Where can I ride a yacht in Dubai?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "We are located in Dubai Marina and offer services in various locations across Dubai, including Palm Jumeirah, Burj Al Arab, The World Islands, Dubai Creek, Jumeirah Beach, and Bluewaters Island."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What are the business hours for yacht rides in Dubai?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "We offer 24-hour service, including holidays, so users can book at any time for their convenience."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What is the best website to book a yacht in Dubai?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "dubaiyachtevents.com is the best website with 0% commission in Dubai."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What is included in the yacht rental package?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "1. Cool drinks\n2. DJ music system with Dolby Atmos\n3. Unlimited drinking water\n4. Life jackets for all passengers, etc."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "Can I customize my yacht rental experience?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "Yes, you can customize your yacht rental experience."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What is the minimum rental period?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "The minimum rental period is 4 hours."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What are the payment options for booking a yacht?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "All types of payments are available, including debit card, credit card, online payments, etc."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "What is your cancellation policy for yacht rentals in Dubai?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "Yes, we provide a 100% full refund for cancellations made at least 24 hours before the travel date."
                   }
                 },
                 {
                   "@type": "Question",
                   "name": "Do you offer yacht rentals for special events such as weddings or corporate parties?",
                   "acceptedAnswer": {
                     "@type": "Answer",
                     "text": "Yes, we offer yacht rentals for all events such as birthday parties, wedding anniversaries, corporate meetings, business parties, etc. However, there is an additional cost for decorations."
                   }
                 }
               ]
             },
             {
               "@type": "Place",
               "name": "Dubai Marina",
               "address": {
                 "@type": "PostalAddress",
                 "streetAddress": "Dubai Marina",
                 "addressLocality": "Dubai",
                 "addressRegion": "Dubai",
                 "addressCountry": "AE"
               }
             },
             {
               "@type": "AggregateRating",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "ratingValue": "4.9",
               "reviewCount": "9675"
             },
             {
               "@type": "Review",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "author": {
                 "@type": "Person",
                 "name": "Mr. Ashi Buff"
               },
               "datePublished": "2024-06-25",
               "reviewBody": "I accidentally found a trusted agency. They provide low-priced rentals even if you are new in Dubai. I recommend them to all.",
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5"
               }
             },
             {
               "@type": "Review",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "author": {
                 "@type": "Person",
                 "name": "Mrs. Mary Lara"
               },
               "datePublished": "2024-07-01",
               "reviewBody": "An amazing experience with Dubai Yacht Events. The staff was very friendly and professional. They are the main reason for 100% fun.",
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5"
               }
             },
             {
               "@type": "Review",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "author": {
                 "@type": "Person",
                 "name": "Adam Eve"
               },
               "datePublished": "2024-06-20",
               "reviewBody": "The yacht was luxurious and well-maintained. We had a fantastic time!",
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5"
               }
             },
             {
               "@type": "Review",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "author": {
                 "@type": "Person",
                 "name": "Rani UAE"
               },
               "datePublished": "2024-06-15",
               "reviewBody": "Great service and an unforgettable experience. Will definitely book again.",
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5"
               }
             },
             {
               "@type": "Review",
               "itemReviewed": {
                 "@type": "LocalBusiness",
                 "name": "Dubai Yacht Events"
               },
               "author": {
                 "@type": "Person",
                 "name": "David Miller Fan"
               },
               "datePublished": "2024-07-03",
               "reviewBody": "I'm from South Africa. Really happy with dubaiyachtevents.com. I compared prices with all agencies, and nobody can compete with them.",
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5"
               }
             },
             {
               "@type": "LocalBusiness",
               "name": "Dubai Yacht Events",
               "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
               "address": {
                 "@type": "PostalAddress",
                 "streetAddress": "Dubai Marina",
                 "addressLocality": "Dubai",
                 "addressRegion": "Dubai",
                 "postalCode": "12345",
                 "addressCountry": "AE"
               },
               "url": "https://dubaiyachtevents.com/",
               "telephone": "+971545619773",
               "priceRange": "AED 379 - AED 2699",
               "sameAs": [
                 "https://m.facebook.com/vcyachtsdubai/",
                 "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
                 "https://pin.it/2CuYXWb",
                 "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
                 "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
                 "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
                 "https://boatrentaldubaimarina.blogspot.com/"
               ],
               "openingHoursSpecification": [
                 {
                   "@type": "OpeningHoursSpecification",
                   "dayOfWeek": [
                     "Monday",
                     "Tuesday",
                     "Wednesday",
                     "Thursday",
                     "Friday",
                     "Saturday",
                     "Sunday"
                   ],
                   "opens": "00:00",
                   "closes": "24:00"
                 }
               ]
             }
           ]
          })}
         </script>
         
         

            
          </Helmet>

          
      
      <Header/>

  
      <ScrollArrows />

 
  <main>
    <article>

    <HeroForm />
      






      <section className="section super-yacht" id="super-yacht">
        <div className="container">

          <div className="title-wrapper">
            <h1 className="h2 section-title">Dubai Yacht Rentals - Yacht Tour Dubai</h1>

          </div>

          <p> You all know that living in Dubai is costly, but a <strong>yacht ride </strong>in Dubai is not as expensive. All people who love yacht rides can enjoy this experience. Confirm your budget and choose a yacht within your price range. Options include <strong>private yachts</strong>, <strong>shared tours</strong>, or <strong>luxury yachts</strong>.A <strong>Dubai yacht ride</strong> offers the opportunity to explore the city's important landmarks. Popular sights include <strong>Dubai Marina</strong>, <strong>Palm Jumeirah</strong>, <strong>Burj Al Arab</strong>, and <strong>Atlantis,The Palm</strong>. Additionally, you can enjoy activities like sunbathing, swimming, underwater diving, <strong>fishing</strong>, and <strong>water sports</strong>, with dining and entertainment options on board.The best time to experience a yacht ride is from October to April. Sunset cruises are particularly beautiful. Book in advance for a memorable experience.Enjoy the <strong>dubai yacht party</strong> .</p>
          <br/>
          <ul className="super-yacht-list">

            <li>
              <div className="super-yacht-card">

                <figure className="card-banner">
                  <img src={yacht_42ft}  title="42ft private yacht" alt="42ft private yacht" loading="lazy" width="440" height="300" className="w-100" />
              </figure>
             

                <div className="card-content">

                  <div className="card-title-wrapper">
                    <h2 className="h3 card-title">
                      <Link title="Redirect to 42Ft Yacht Dubai page" to="/yacht42ft"><strong>42Ft Yacht Dubai</strong></Link>
                    </h2>

                    <data className="place" value="Dubai">Marina</data>
                  </div>
                  <ul className="card-list">

                    <li className="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span className="card-item-text">12 People</span>
                    </li>

                    <li className="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span className="card-item-text">AddOn Food Items</span>
                    </li>
                    

                    <li className="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span className="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li className="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span className="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div className="card-price-wrapper">

                    <p className="card-price">
                      <strong>AED 449</strong> / hour
                    </p>

                    <button className="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link className="btn" title="Redirect to 42Ft Yacht Dubai page" to="/yacht42ft">Book Now</Link>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="super-yacht-card">

                <figure className="card-banner">
                  <img src={yacht_44ft} title="44ft private yacht" alt="44ft private yacht" loading="lazy" width="440" height="300" className="w-100" />
              </figure>
              

                <div className="card-content">

                  <div className="card-title-wrapper">
                    <h2 className="h3 card-title">
                      <Link title="Redirect to 44Ft Yacht Dubai page" to="/yacht44ft"><strong>44Ft Yacht Dubai</strong></Link>
                    </h2>

                    <data className="place" value="Dubai">Marina</data>
                  </div>

                  <ul className="card-list">

                    <li className="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span className="card-item-text">12 People</span>
                    </li>

                    <li className="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span className="card-item-text">AddOn Food Items</span>
                    </li>
                    

                    <li className="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span className="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li className="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span className="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div className="card-price-wrapper">

                    <p className="card-price">
                      <strong>AED 499</strong> / hour
                    </p>

                    <button className="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link className="btn" title="Redirect to 44Ft Yacht Dubai page" to="/yacht44ft">Book Now</Link>

                  </div>

                </div>

              </div>
              </li>
              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_45ft} title="45ft private yacht" alt="45ft private yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 45Ft Yacht Dubai page" to="/yacht45ft"><strong>45Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">12 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 499</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 45Ft Yacht Dubai page" to="/yacht45ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_55ft} title="55ft azimut yacht" alt="55ft azimut yacht" loading="lazy" width="440" height="300" className="w-100" />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 55Ft Azimut Yacht Dubai page" to="/yacht_azimut_55ft"><strong>55Ft Azimut Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">14 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 549</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 55Ft Azimut Yacht Dubai page" to="/yacht_azimut_55ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_55_1ft} title="55ft luxury yacht" alt="55ft luxury yacht" loading="lazy" width="440" height="300" className="w-100" />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 55Ft Yacht Dubai page" to="/yacht_luxury_55ft"><strong>55Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">22 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 599</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 55Ft Yacht Dubai page" to="/yacht_luxury_55ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_56ft} title="56ft luxury yacht" alt="56ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 56Ft Yacht Dubai page" to="/yacht_luxury_56ft"><strong>56Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">20 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 699</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 56Ft Yacht Dubai page" to="/yacht_luxury_56ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_65ft} title="65ft luxury yacht" alt="65ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
               
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 65Ft Yacht Dubai page" to="/yacht_luxury_65ft"><strong>65Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">25 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 749</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 65Ft Yacht Dubai page" to="/yacht_luxury_65ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_68ft} title="68ft luxury yacht" alt="68ft luxury yacht" loading="lazy" width="440" height="300" className="w-100" />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 68Ft Yacht Dubai page" to="/yacht_luxury_68ft"><strong>68Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">25 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 799</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 68Ft Yacht Dubai page" to="/yacht_luxury_68ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_75ft} title="75ft luxury yacht" alt="75ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 75Ft Yacht Dubai page" to="/yacht_luxury_75ft"><strong>75Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">30 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 949</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 75Ft Yacht Dubai page" to="/yacht_luxury_75ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_80ft} title="80ft luxury yacht in Dubai on a blue sea" alt="80ft luxury yacht in Dubai on a blue sea" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 80Ft Yacht Dubai page" to="/yacht_80ft"><strong>80Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">35 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1199</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 80Ft Yacht Dubai page" to="/yacht_80ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={luxury_yacht_80ft} title="80ft dream luxury yacht" alt="80ft dream luxury yacht" loading="lazy" width="440" height="300" className="w-100"  /> 
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 80Ft Luxury Yacht Dubai page" to="/yacht_luxury_80ft"><strong>80Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">35 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1199</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 80Ft Luxury Yacht Dubai page" to="/yacht_luxury_80ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={luxury_yacht_88ft} title="88ft luxury yacht" alt="88ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 88Ft Luxury Yacht Dubai page" to="/yacht_luxury_88ft"><strong>88Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">50 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1899</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 88Ft Luxury Yacht Dubai page" to="/yacht_luxury_88ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={yacht_90ft} title="90ft luxury yacht" alt="90ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 90Ft Yacht Dubai page" to="/yacht_90ft"><strong>90Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">65 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1499</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 90Ft Yacht Dubai page" to="/yacht_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={luxury_yacht_90ft} title="90ft premium yacht" alt="90ft premium yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 90Ft Luxury Yacht Dubai page" to="/yacht_luxury_90ft"><strong>90Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">40 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1449</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 90Ft Luxury Yacht Dubai page" to="/yacht_luxury_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={vip_luxury_yacht_90ft} title="90ft vip yacht" alt="90ft vip yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 90Ft Luxury Vip Yacht Dubai page" to="/yacht_luxury_vip_90ft"><strong>90Ft Luxury Vip Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">50 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 2399</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 90Ft Luxury Vip Yacht Dubai page" to="/yacht_luxury_vip_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={luxury_yacht_95ft} title="95ft luxury yacht" alt="95ft luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 95Ft Luxury Yacht Dubai page" to="/yacht_luxury_95ft"><strong>95Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">50 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 1999</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 95Ft Luxury Yacht Dubai page" to="/yacht_luxury_95ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={family_yacht_100ft} title="100ft luxury family yacht" alt="100ft luxury family yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 100Ft Family Yacht Dubai page" to="/yacht_family_100ft"><strong>100Ft Family Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">80 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 2699</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 100Ft Family Yacht Dubai page" to="/yacht_family_100ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={premium_luxury_yacht_101ft} title="101ft super luxury yacht" alt="101ft super luxury yacht" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 101Ft Premium Luxury Yacht Dubai page" to="/yacht_premium_luxury_101ft"><strong>101Ft Premium Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">65 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 2499</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 101Ft Premium Luxury Yacht Dubai page" to="/yacht_premium_luxury_101ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>


          </ul>

        </div>
      </section>

      

      <section className="section get-start">
        <div className="container">

          <h2 className="h2 section-title"><strong>Book a Yacht in Dubai for Party</strong> in just four simple steps</h2>

          <ul className="get-start-list">

            <li>
              <div className="get-start-card">

                <div className="card-icon icon-1">
                  <ion-icon name="search-outline"></ion-icon>
                </div>

                <h3 className="card-title">Search for a Yacht</h3>

                <p className="card-text">
                  Search for the <strong>yacht </strong>in Dubai from the group of listings
                </p>

                <Link title="" to="#super-yacht" className="card-link">Get started</Link>

              </div>
            </li>

            <li>
              <div className="get-start-card">

                <div className="card-icon icon-2">
                  <ion-icon name="boat-outline"></ion-icon>
                </div>

                <h3 className="card-title">Choose a Yacht</h3>

                <p className="card-text">
                  Choose the <strong>best luxury yacht </strong>in Dubai that particularly attracts you the most 
                </p>

              </div>
            </li>

            <li>
              <div className="get-start-card">

                <div className="card-icon icon-3">
                  <ion-icon name="checkmark-outline"></ion-icon>
                </div>

                <h3 className="card-title">Check the Description</h3>

                <p className="card-text">
                  Confirm the yacht by checking if the description includes the expected features
                </p>

              </div>
            </li>

            <li>
              <div className="get-start-card">

                <div className="card-icon icon-4">
                  <ion-icon name="call"></ion-icon>
                </div>

                <h3 className="card-title">Make a deal</h3>

                <p className="card-text">
                  Click 'Rent Now' option below the yacht to finalize your deal on WhatsApp
                </p>

              </div>
            </li>

          </ul>

        </div>
      </section>

      <section className="section super-yacht" id="super-boat">
        <div className="container" id="explore-boat">

          <div className="title-wrapper">
            <h1 className="h2 section-title"><strong>Dubai Boat Ride</strong> - <strong>Dubai Boat Rental</strong></h1>

            <Link title="" to="#" className="super-yacht-link">
              <span>View more</span>

              <ion-icon name="arrow-forward-outline"></ion-icon>
            </Link>
          </div>
          <p>Of course, some families or individuals can afford yachts. For those who can't, an alternative option is a <strong>boat ride </strong>in Dubai. Many families who love privacy and prefer a <strong> budget-friendly Yacht & Boat ride </strong>choose this option. It provides a personal <strong>family boat </strong> in dubai, allowing them to spend time together without disturbance, in a peaceful environment.</p>
          <br />
          <ul className="super-yacht-list">

            <li>
              <div className="super-yacht-card">

                <figure className="card-banner">
                  <img src={boat_33ft} title="33ft boat" alt="33ft boat" loading="lazy" width="440" height="300" className="w-100"  />
              </figure>
              

                <div className="card-content">

                  <div className="card-title-wrapper">
                    <h2 className="h3 card-title">
                      <Link title="Redirect to 33Ft Boat Dubai page" to="/boat_33ft"><strong>33Ft Boat Dubai</strong> Marina Boat</Link>
                    </h2>

                    <data className="place" value="Dubai">Marina</data>
                  </div>

                  <ul className="card-list">

                    <li className="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span className="card-item-text">10 People</span>
                    </li>

                    <li className="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span className="card-item-text">AddOn Food Items</span>
                    </li>
                    

                    <li className="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span className="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li className="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span className="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div className="card-price-wrapper">

                    <p className="card-price">
                      <strong>AED 379</strong> / hour
                    </p>

                    <button className="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link className="btn" title="Redirect to 33Ft Boat Dubai page" to="/boat_33ft">Book Now</Link>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div className="super-yacht-card">

                <figure className="card-banner">
                  <img src={boat_36ft} title="36ft boat" alt="36ft boat" loading="lazy" width="440" height="300" className="w-100"  />
              </figure>
             

                <div className="card-content">

                  <div className="card-title-wrapper">
                    <h2 className="h3 card-title">
                      <Link title="Redirect to 36Ft Boat Dubai page" to="/boat_36ft"><strong>36Ft Boat Dubai</strong> Marina Boat</Link>
                    </h2>

                    <data className="place" value="Dubai">Marina</data>
                  </div>

                  <ul className="card-list">

                    <li className="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span className="card-item-text">10 People</span>
                    </li>

                    <li className="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span className="card-item-text">AddOn Food Items</span>
                    </li>
                    

                    <li className="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span className="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li className="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span className="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div className="card-price-wrapper">

                    <p className="card-price">
                      <strong>AED 399</strong> / hour
                    </p>

                    <button className="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link className="btn" title="Redirect to 36Ft Boat Dubai page" to="/boat_36ft">Book Now</Link>

                  </div>

                </div>

              </div>
            </li>
            <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={boat_40ft} title="40ft luxury boat" alt="40ft luxury boat" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
              
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to 40Ft Boat Dubai page" to="/boat_40ft"><strong>40Ft Boat Dubai</strong> Marina Boat</Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">10 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 449</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to 40Ft Boat Dubai page" to="/boat_40ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div className="super-yacht-card">
  
                  <figure className="card-banner">
                    <img src={speed_boat} title="speed boat" alt="speed boat" loading="lazy" width="440" height="300" className="w-100"  />
                </figure>
                
  
                  <div className="card-content">
  
                    <div className="card-title-wrapper">
                      <h2 className="h3 card-title">
                        <Link title="Redirect to Speed Boat Dubai page" to="/speed_boat"> <strong>Speed Boat Dubai</strong> Marina Boat</Link>
                      </h2>
  
                      <data className="place" value="Dubai">Marina</data>
                    </div>
  
                    <ul className="card-list">
  
                      <li className="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span className="card-item-text">10 People</span>
                      </li>
  
                      <li className="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span className="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li className="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span className="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li className="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span className="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div className="card-price-wrapper">
  
                      <p className="card-price">
                        <strong>AED 399</strong> / hour
                      </p>
  
                      <button className="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link className="btn" title="Redirect to Speed Boat Dubai page" to="/speed_boat">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              
          </ul>

        </div>
      </section><br />



      <section className="yacht-section">
        <div className="content">
          <h1><strong>Dubai Yacht Club</strong></h1><br/>
            <h2 className="title">The Advantages of Choosing Us</h2>
            <h3 className="main-title">We've been here for almost 20 years with satisfied and loyal customers</h3>
            <p className="description">Initially, we started with a small crew, but your love and support have brought us to the top of <strong>Dubai's luxury yacht rental </strong>industry</p>
            <ul className="features">
                <li className="feature-item"><span className="icon">&#9679;</span> We provide all types of water activities, including yacht rental, boat rental, water fun rides , and family fishing etc</li>
                <li className="feature-item"><span className="icon">&#9679;</span> We are No 1 in the market with a trusted 4.9-star rating from real users</li>
                <li className="feature-item"><span className="icon">&#9679;</span> Professional captains with more than 10 years of experience </li>
                <li className="feature-item"><span className="icon">&#9679;</span> Just a 4-click online booking process</li>
            </ul>
        </div>
        <div className="image-container">
            <img src={why_choose_us} title="Stylish yacht on the Dubai blue water" alt="Stylish yacht on the Dubai blue water" className="yacht-image"/>
        </div>
    </section><br/>



  <section>
    <div className="container">
    <div className="title-wrapper" >
      <h1 className="h2 section-title">Exciting <strong>Water Sports Dubai</strong></h1></div>
      <p className="description">Most adults and children prefer <strong>water sports</strong> over <strong>yacht</strong> or <strong>boat rides</strong>. The reason behind this is that <strong>water sports</strong> offer more thrill, excitement, and adrenaline. Some of the <strong>popular water sports </strong>in Dubai include <strong>banana rides</strong>, <strong>jet skiing</strong>, <strong>jet cars</strong>, <strong>donut rides</strong>, <strong>flyboarding</strong>, and <strong>parasailing</strong>.Habibi, come to Dubai with your family and don't forget to enjoy the water fun, which is very popular here. Make sure to visit the <strong>most popular places </strong>in Dubai and experience all the excitement they have to offer.</p>
    </div><br/>
      <div className="location" id="location">
        <div className="location-card">
            <div className="waterride">
                <img src={parasailing_ride} title="Couples parasailing with a red parachute above the Dubai sea" alt="Couples parasailing with a red parachute above the Dubai sea" />
            </div>
            <div className="details">
                <h2><strong>parasailing dubai</strong></h2>
                <p>Parasailing is offered in Dubai in various locations, mainly in beach areas like <strong>Dubai Marina</strong> and <strong>Jumeirah Beach Residence</strong> etc...</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
            <div className="waterride">
                <img src={donut_ride} title="Woman wearing a blue life jacket on a fast-moving yellow donut ride" alt="Woman wearing a blue life jacket on a fast-moving yellow donut ride" />
            </div>
            <div className="details">
                <h2><strong>donut ride dubai</strong></h2>
                <p>The donut ride is a <strong>popular water sports </strong>in Dubai, where a donut-shaped tube is tied with a rope to a speedboat and pulled at high speed...</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
            <div className="waterride">
                <img src={jetski_ride} title="Two men riding individual jet skis in dubai" alt="Two men riding individual jet skis in dubai" />
            </div>
            <div className="details">
                <h2><strong>jetski dubai</strong></h2>
                <p>Jet skiing is a thrilling <strong>watersport in Dubai </strong>where you ride a personal watercraft at high speeds. It's the best choice for those who love adventure...</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
            <div className="waterride">
                <img src={wakeboard_ride} title="Man performing a stunt on a wakeboard in Dubai" alt="Man performing a stunt on a wakeboard in Dubai" />
            </div>
            <div className="details">
                <h2><strong>wakeboarding dubai</strong></h2>
                <p>Wakeboard is a <strong>thrilling watersports </strong>in Dubai, where a Wakeboard is tied in the speed boat with this you can ride faster with thriller...</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
            <div className="waterride">
                <img src={jetcar_ride} title="Friends riding in a yellow jet car on the water in Dubai" alt="Friends riding in a yellow jet car on the water in Dubai" />
            </div>
            <div className="details">
                <h2><strong>jetcar dubai</strong></h2>
                <p>A jetcar ride is a fun-filled experience in Dubai where a car-shaped speedboat is given under your control, allowing you to drive it like a real car on water, making it an enjoyable activity for you and your family...</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
            <div className="waterride">
                <img src={flyboard_ride} title="Woman wearing a helmet performing a stunt on a flyboard in Dubai" alt="Woman wearing a helmet performing a stunt on a flyboard in Dubai" />
            </div>
            <div className="details">
                <h2><strong>flyboarding dubai</strong></h2>
                <p>A flyboard is an <strong>expert-level watersport </strong>in Dubai. Not everyone can handle a flyboard, as it requires skill and balance. It involves strapping a shoe with water propulsion to your leg, allowing you to fly with the help of water force</p>
                <button>More Details</button>
            </div>
        </div>
        <div className="location-card">
          <div className="waterride">
              <img src={banana_ride} title="Family enjoying a banana ride in Dubai" alt="Family enjoying a banana ride in Dubai" />
          </div>
          <div className="details">
              <h2><strong>banana boat dubai</strong></h2>
              <p>A big banana-shaped small boat is attached to a speed boat. You can ride it with your friends or family. Banana rides look more fun and entertaining.</p>
              <button>More Details</button>
          </div>
      </div>
    </div>
  </section>



  <div className="form-container unique-form">
    <h1>Check Availability</h1>
    <AvailabilityCheck/>
</div>


    </article>
  </main>
  <br />
  
  




<section className="pricing-sections-container">
  <div className="pricing-table">
    <div className="table-container">
      <table className="styled-table">
      <section className="pricing-table" >
    <h2>Yacht Dubai Price</h2>
    <div className="table-container">
        <table className="styled-table">
            <thead>
                <tr>
                    <th><h1>Yachts for Sale</h1></th>
                    <th><h1>Yacht Price</h1></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Link to="/yacht42ft"><strong>42Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht42ft"><strong>449 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht44ft"><strong>44Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht44ft"><strong>499 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht45ft"><strong>45Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht45ft"><strong>499 AED(Dirhams)/12 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_azimut_55ft"><strong>55Ft Azimut Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_azimut_55ft"><strong>549 AED(Dirhams)/14 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_55ft"><strong>55Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_55ft"><strong>599 AED(Dirhams)/22 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_56ft"><strong>56Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_56ft"><strong>699 AED(Dirhams)/20 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_65ft"><strong>65Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_65ft"><strong>749 AED(Dirhams)/25 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_68ft"><strong>68Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_68ft"><strong>799 AED(Dirhams)/25 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_75ft"><strong>75Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_75ft"><strong>949 AED(Dirhams)/30 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_80ft"><strong>80Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_80ft"><strong>1199 AED(Dirhams)/35 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_80ft"><strong>80Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_80ft"><strong>1199 AED(Dirhams)/35 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_88ft"><strong>88Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_88ft"><strong>1899 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_90ft"><strong>90Ft Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_90ft"><strong>1499 AED(Dirhams)/65 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_90ft"><strong>90Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_90ft"><strong>1499 AED(Dirhams)/40 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_vip_90ft"><strong>90Ft Luxury Vip Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_vip_90ft"><strong>2399 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_luxury_95ft"><strong>95Ft Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_luxury_95ft"><strong>1999 AED(Dirhams)/50 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_family_100ft"><strong>100Ft Family Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_family_100ft"><strong>2699 AED(Dirhams)/80 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/yacht_premium_luxury_101ft"><strong>101Ft Premium Luxury Yacht Dubai</strong></Link></td>
                    <td><Link to="/yacht_premium_luxury_101ft"><strong>2499 AED(Dirhams)/65 Guest</strong></Link></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
      </table>
    </div>
  </div>

  <div className="pricing-table">
    <div className="table-container">
      <table className="styled-table">
      <section className="pricing-table" >
  <h2>Boat ride dubai price</h2>
  <div className="table-container">
      <table className="styled-table">
          <thead>
              <tr>
                  <th><h1>Boats for Sale</h1></th>
                  <th><h1>Boat Price</h1></th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td><Link to="/boat_33ft"><strong>33Ft Boat Dubai</strong></Link></td>
                  <td><Link to="/boat_33ft"><strong>379 AED(Dirhams)/10 Guest</strong></Link></td>
              </tr>
              <tr>
                  <td><Link to="/boat_36ft"><strong>36Ft Boat Dubai</strong></Link></td>
                  <td><Link to="/boat_36ft"><strong>399 AED(Dirhams)/10 Guest</strong></Link></td>
              </tr>
              <tr>
                  <td><Link to="/boat_40ft"><strong>40Ft Boat Dubai</strong></Link></td>
                  <td><Link to="/boat_40ft"><strong>449 AED(Dirhams)/10 Guest</strong></Link></td>
              </tr>
              <tr>
                  <td><Link to="/speed_boat"><strong>Speed Boat Dubai</strong></Link></td>
                  <td><Link to="/speed_boat"><strong>399 AED(Dirhams)/10 Guest</strong></Link></td>
              </tr>               
          </tbody>
      </table>
  </div>
</section>
      </table>
    </div>
  </div>
</section>


  <div className="faq-container">
    <h1>Frequently Asked Questions (FAQ)</h1>

    <div className="faq-section">
        <h2>General Questions</h2>
        <div className="faq-item">
            <h2>Q1: how to <strong>book yacht or boat </strong> in dubai?</h2>
            <p>Search for the <strong>best yacht or boat rental website </strong>in Dubai that offers low prices, no hidden fees, good customer support, friendly staff, and free cancellation. Check their background to see how long they have been in this field.</p>
        </div>
        <div className="faq-item">
            <h2>Q2: Where i can <strong>ride yacht </strong> in dubai ?</h2>
            <p>We are located in Dubai Marina and offer services in various locations across Dubai, including <strong>Palm Jumeirah</strong>, <strong>Burj Al Arab</strong>, <strong>The World Islands</strong>, <strong>Dubai Creek</strong>, <strong>Jumeirah Beach</strong>, and <strong>Bluewaters Island</strong>.<a title="" href="https://dubaiyachtevents.com/">Contact us</a></p>
        </div>
        <div className="faq-item">
            <h2>Q3: What is the <strong>businness hours to yacht ride </strong> in dubai?</h2>
            <p>We offer 24-hour service, including holidays, so users can book at any time for their convenience</p>
        </div>
        <div className="faq-item">
          <h2>Q4: What is the <strong>best website to book a yacht </strong>in Dubai?</h2>
          <p><a title="Redirect to initial position" href="https://dubaiyachtevents.com/" target="_blank" rel='noreferrer'>DubaiYachtEvents.com</a> is the best website with 0% commission in Dubai.</p>
        </div>
        <div className="faq-item">
          <h2>Q5: What is included in the <strong>yacht rental package</strong>?</h2>
          <ul><li><p>1. Cool Drinks</p></li>
                 <li><p>2. Dj Music System with dolby atmos</p></li>
                <li><p>3. Unlimited Drinking Water</p></li>
                <li><p>4. Life Jacket for all passengers etc</p></li>
            </ul>
        </div>
        <div className="faq-item">
          <h2>Q6: Can I customize my <strong>yacht rental experience</strong>?</h2>
          <p>Yes you can customize your yacht rental experience.</p>
      </div>
      <div className="faq-item">
        <h2>Q7: What are the <strong>payment options for booking a yacht</strong>?</h2>
        <p>All types of payments are available, including debit card, credit card, online payments, etc.</p>
    </div>
    <div className="faq-item">
      <h2>Q8: What is your cancellation policy?</h2>
      <p>Yes, we provide a 100% full refund for cancellations made at least 24 hours before the travel.</p>
  </div>
  <div className="faq-item">
    <h2>Q9: How many guests can the yacht accommodate?</h2>
    <p>It is based on the size of the yacht, starting from 8 AED and going up to 100 AED. Read our yacht description for more details. </p>
</div>
<div className="faq-item">
  <h2>Q10: Do you <strong>offer yacht rentals</strong> for special events such as weddings or corporate party?</h2>
  <p>Yes, we offer yacht rental for all events such as birthday parties, wedding anniversaries, corporate meetings, business parties, etc. However, there is an additional cost for decorations.</p>
</div>
<div className="faq-item">
  <h2>Q11: Are there any additional fees or charges not included in the rental price?</h2>
  <p>No, there are no additional fees or charges. All fees are included in the price.</p>
</div>
<div className="faq-item">
  <h2>Q12: Do you provide experienced crew members with the <strong>yacht rental</strong>?</h2>
  <p>Yes, we have an experienced crew who will guide you throughout the entire ride.</p>
</div>
<div className="faq-item">
  <h2>Q13: Can I bring my own food and beverages on board?</h2>
  <p>Yes, you can bring your own food; there are no restrictions on that.</p>
</div>
<div className="faq-item">
  <h2>Q14: Do you offer any discounts or promotions for <strong>long-term yacht rentals</strong>?</h2>
  <p>Yes, you can get a discount based on the hours you travel. Contact our support for more details.</p>
</div>
        
    </div>

    

   

   
</div>



<Footer/>








</div>
    );
}

export default Home;
