import React from 'react';
import { Link } from 'react-router-dom';
import './Not-found.css'; 
import Header from '../Header';
import Footer from '../Footer';

function NotFound() {
  return (
    <>
    <Header/>
    <div className="not-found-container" >
      <div className="error-code">
        <h1>404</h1>
      </div>
      <div className="message">
        <p>Oops! The page you're looking for doesn't exist.</p>
        <p className='trust'>
        It seems this yacht is still under development. Let’s guide you back to a smoother journey.
        </p>
        <Link to="/" className="go-home">
          Go to Home
        </Link>
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default NotFound;
