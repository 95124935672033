import React, { useState } from 'react';

const AvailabilityCheck = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    date: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Function to handle form submission
  const sendToWhatsApp = (e) => {
    e.preventDefault(); // Prevent form from submitting normally

    const { name, mobileNumber, date } = formData;
    const message = `Yacht / Boat Name: ${name}\nMobile Number: ${mobileNumber}\nDate of Ride: ${date}`;
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/971545619773?text=${encodedMessage}`;
    
    window.open(whatsappUrl, '_blank'); // Open WhatsApp in a new tab
  };

  return (
    <form id="uniqueForm" onSubmit={sendToWhatsApp}>
      <label htmlFor="uniqueName">Yacht / Boat Name:</label>
      <select
        id="uniqueName"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select Yacht / Boat</option>
        <option value="Bithday Party Yacht">Bithday Party Yacht</option>
        <option value="Full Night Party Yacht">Full Night Party Yacht</option>
        <option value="Wedding Aniversary Yacht Rental">Wedding Aniversary Yacht Rental</option>
        <option value="Private Meeting Yacht Hire">Private Meeting Yacht Hire</option>
        <option value="Business party Yacht">Business party Yacht</option>
        <option value="42Ft Yacht Dubai ">42Ft Yacht Dubai </option>
        <option value="44Ft Yacht Dubai ">44Ft Yacht Dubai </option>
        <option value="45Ft Yacht Dubai ">45Ft Yacht Dubai </option>
        <option value="55Ft Azimut Yacht Dubai ">55Ft Azimut Yacht Dubai </option>
        <option value="55Ft Yacht Dubai ">55Ft Yacht Dubai </option>
        <option value="56Ft Yacht Dubai ">56Ft Yacht Dubai </option>
        <option value="65Ft Yacht Dubai ">65Ft Yacht Dubai </option>
        <option value="68Ft Yacht Dubai ">68Ft Yacht Dubai </option>
        <option value="75Ft Yacht Dubai ">75Ft Yacht Dubai </option>
        <option value="80Ft Yacht Dubai ">80Ft Yacht Dubai </option>
        <option value="80Ft Luxury Yacht Dubai ">80Ft Luxury Yacht Dubai </option>
        <option value="88Ft Luxury Yacht Dubai ">88Ft Luxury Yacht Dubai </option>
        <option value="90Ft Yacht Dubai ">90Ft Yacht Dubai </option>
        <option value="90Ft Luxury Yacht Dubai ">90Ft Luxury Yacht Dubai </option>
        <option value="90Ft Luxury Vip Yacht Dubai ">90Ft Luxury Vip Yacht Dubai </option>
        <option value="95Ft Luxury Yacht Dubai ">95Ft Luxury Yacht Dubai </option>
        <option value="100Ft Family Yacht Dubai ">100Ft Family Yacht Dubai </option>
        <option value="101Ft Premium Luxury Yacht Dubai ">101Ft Premium Luxury Yacht Dubai </option>
        <option value="33Ft Boat Dubai ">33Ft Boat Dubai </option>
        <option value="36Ft Boat Dubai ">36Ft Boat Dubai </option>
        <option value="42 Ft Aquaholic Boat">40Ft Boat Dubai </option>
        <option value="Speed Boat Dubai ">Speed Boat Dubai </option>
      </select>
      <label htmlFor="uniqueMobileNumber">Mobile Number:</label>
      <input
        type="text"
        id="uniqueMobileNumber"
        name="mobileNumber"
        value={formData.mobileNumber}
        onChange={handleChange}
        required
      />
      <label htmlFor="uniqueDate">Date of Ride:</label>
      <input
        type="date"
        id="uniqueDate"
        name="date"
        value={formData.date}
        onChange={handleChange}
        required
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default AvailabilityCheck;
