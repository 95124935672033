import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_luxury_68ft from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_luxury_68ft_hall from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-hall.webp'
import yacht_luxury_68ft_bedroom from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-bed-room.webp';
import yacht_luxury_68ft_top_view from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-top-view.webp';
import yacht_luxury_68ft_dining from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-side-dining-table.webp';
import yacht_luxury_68ft_side_view from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-side-view.webp';
import yacht_luxury_68ft_back_view from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-back-view.webp';
import yacht_luxury_68ft_hall_back_view from'../assets/images/yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental/68-ft-yacht-hall-back view.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function YachtLuxury68ft() {
  const hoverImages = [
    { src: yacht_luxury_68ft, alt: "68ft luxury yacht in dubai" },
    { src: yacht_luxury_68ft_bedroom, alt: "68ft luxury yacht bedroom in dubai" },
    { src: yacht_luxury_68ft_dining, alt: "68ft luxury yacht dining table in dubai" },
    { src: yacht_luxury_68ft_hall, alt: "68ft luxury yacht hall in dubai" },
    { src: yacht_luxury_68ft_top_view, alt: "68ft luxury yacht top view in dubai" },
  ];

    return (

        <div>

<Helmet>

<title>68Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 68ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 31 (Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 68ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_luxury_68ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_luxury_68ft",
          "url": "https://dubaiyachtevents.com/yacht_luxury_68ft",
          "name": "68Ft Yacht for Rent - Book Online",
          "description": "Discover the best 68ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 31 (Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "68Ft Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 68ft yacht for rent in Dubai, accommodating up to 25 guests, for AED 31 (Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT0012",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "699.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_luxury_68ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "24598"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/68ft-yacht-dubai-dubai-marina-yacht-rental.23b6dacc18e78163a016.webp",
            "https://dubaiyachtevents.com/static/media/68-ft-yacht-bed-room.9533391bd03eab960c18.webp",
            "https://dubaiyachtevents.com/static/media/68-ft-yacht-side-dining-table.dfb2019fe153d5753a00.webp",
            "https://dubaiyachtevents.com/static/media/68-ft-yacht-hall.0c33ba396348aab18d4d.webp",
            "https://dubaiyachtevents.com/static/media/68-ft-yacht-top-view.9c697db9ebea21951864.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 68ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 68ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 68ft yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 68ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 25 person can travel per ride in 68 ft yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 68ft yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 68ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 68ft yacht in dubai is approximately AED 699"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "699 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
    <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht rental",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "68ft yacht",
        "item": "https://dubaiyachtevents.com/yacht_luxury_68ft"
      }
    ]
  })}
  </script>




     
     </Helmet>


       <Header/>

       <ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_luxury_68ft} 
                     mainAlt="68ft luxury yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">68Ft Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 799</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(958 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                            <li>1. <b>Crew:</b>  Our crew and captain are highly skilled and experienced, and the maximum number of crew members is arranged for this yacht.</li>
                            <li>2. <b>Entertainment:</b>  This is a party yacht focused on design especially for party like Wedding aniversary,Birthday party etc</li>
                            <li>3. <b>Fishing:</b> Fishing tools are provided for you to catch fish and enjoy the party with more fun.</li>
                            <li>4. <b>Swimming:</b> Swimming options are available. Try it before your trip ends.</li>
                            <li>4. <b>Starters:</b>  Cool Drinks will be provided to all passengers.</li>
                            <li>5. <b>Safety:</b>  We provide higher security and more privacy than others in Dubai.</li>
                            <li>6. <b>Food:</b>  You can order your favorite meals at any time during your trip. Enjoy your dining experience!</li>
                        
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2068ft%20Sea%20Empress%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2068ft%20Sea%20Empress%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. The maximum number of 25 peoples can travel per Ride in this yacht</p>
                <p style={{marginTop: '2%'}}>2. It is a budget party yacht where anyone can get a chance</p>
                <img className="desc-img" src={yacht_luxury_68ft_side_view} alt="68ft luxury yacht side view in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_luxury_68ft_side_view} alt="68ft luxury yacht side view in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. Stay cool in summer; we provide cool drinks and soft drinks for all passengers</p>
                <img className="desc-img" src={yacht_luxury_68ft_back_view} alt="68ft luxury yacht back view in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_luxury_68ft_back_view} alt="68ft luxury yacht back view in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>4. We provide free cancellation only for customers who book online</p>
                <p style={{marginTop: '2%'}}>5. This is an party yacht so we focused on party like better lightings,high and crystal clear sound dj system,And coverd with Ac</p>
                <p style={{marginTop: '2%'}}>6. This yacht comes with a large bedroom for your convenience</p>
                <p style={{marginTop: '2%'}}>7. For those who want to enjoy the party at the best price, you can go for the 68ft Sea Empress yacht.</p>
                <img className="desc-img" src={yacht_luxury_68ft_hall_back_view} alt="68ft luxury yacht hall in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_luxury_68ft_hall_back_view} alt="68ft luxury yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>




            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>

 
</div>



);
}

export default YachtLuxury68ft;