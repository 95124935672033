import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_luxury_90ft from'../assets/images/yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_luxury_90ft_hall from'../assets/images/yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental/90-ft-marine-splendor-yacht-hall.webp'
import yacht_luxury_90ft_bedroom from'../assets/images/yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental/90-ft-marine-splendor-yacht-bedroom.webp';
import yacht_luxury_90ft_balcony from'../assets/images/yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental/90-ft-marine-splendor-yacht-portico.webp';
import yacht_luxury_90ft_bathtub from'../assets/images/yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental/90-ft-marine-splendor-yacht-bathtub.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function YachtLuxury90ft() {
  const hoverImages = [
    { src: yacht_luxury_90ft, alt: "90ft super premium luxury yacht in dubai" },
    { src: yacht_luxury_90ft_bathtub, alt: "90ft super premium luxury yacht bathtub in dubai" },
    { src: yacht_luxury_90ft_bedroom, alt: "90ft super premium luxury yacht bedroom in dubai" },
    { src: yacht_luxury_90ft_hall, alt: "90ft super premium luxury yacht hall in dubai" },
    { src: yacht_luxury_90ft_balcony, alt: "90ft super premium luxury yacht portico in dubai" },
  ];

    return (

<div>

<Helmet>
     
<title>90Ft Luxury Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 90ft luxury yacht for rent in Dubai, accommodating up to 40 guests, for AED 36 (Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 90ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_luxury_90ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_luxury_90ft",
          "url": "https://dubaiyachtevents.com/yacht_luxury_90ft",
          "name": "90Ft Luxury Yacht for Rent - Book Online",
          "description": "Discover the best 90ft luxury yacht for rent in Dubai, accommodating up to 40 guests, for AED 36 (Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "90Ft Luxury Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 90ft luxury yacht for rent in Dubai, accommodating up to 40 guests, for AED 36 (Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT0018",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "1399.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_luxury_90ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "6598"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental.114e23411220a4f797e3.webp",
            "https://dubaiyachtevents.com/static/media/90-ft-marine-splendor-yacht-bathtub.03fc38ef704458bc9a5d.webp",
            "https://dubaiyachtevents.com/static/media/90-ft-marine-splendor-yacht-bedroom.24932f325aa828f4bc27.webp",
            "https://dubaiyachtevents.com/static/media/90-ft-marine-splendor-yacht-hall.88c661682d8924b289af.webp",
            "https://dubaiyachtevents.com/static/media/90-ft-marine-splendor-yacht-portico.b7309c23a2650f8cc080.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 90ft marine yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaimarine yachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 90ft marine yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 90ft marine yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 90ft marine yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 40 person can travel per ride in 90 ft marine yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 90ft marine yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaimarine yachtevents.com is the best website to book marine yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 90ft marine yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 90ft marine yacht in dubai is approximately AED 1399"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "1399 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
    <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "yacht rental",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "90ft luxury",
        "item": "https://dubaiyachtevents.com/yacht_luxury_90ft"
      }
    ]
  })}
  </script>




     </Helmet>

<Header/>

<ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_luxury_90ft} 
                     mainAlt="90ft super premium luxury yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">90Ft Luxury Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 1499</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(5469 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                      <li>1. <b>Crew:</b> Experienced captain and crew on the yacht</li>
                        <li>2. <b>Entertainment:</b> High-quality DJ sound system available</li>
                        <li>3. <b>Safety:</b> We guarantee the safety of the passengers, so we provide life jackets for all</li>
                        <li>4. <b>Fishing:</b>Fishing tools are provided for all passengers.</li>
                        <li>5. <b>Swimming:</b> Bathtub available in this yacht.</li>
                        <li>6. <b>Starters:</b> Cool drinks or soft drinks provided for all</li>
                        
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2090ft%20Marine%20Splendor%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2090ft%20Marine%20Splendor%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. Upto 40 person can travel per ride</p>
                <p style={{marginTop: '2%'}}>2. A bathtub surrounded by windows. You can imagine the feel.</p>
                <img className="desc-img" src={yacht_luxury_90ft_hall} alt="90ft super premium luxury yacht hall in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_luxury_90ft_hall} alt="90ft super premium luxury yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. A small rounded staircase is available for use to reach the top of the yacht.</p>
                <p style={{marginTop: '2%'}}>4. A bathtub with hot water, along with cooked drinks, will make you feel like you're in heaven.A massive 98 inch tv in the bedroom  gives a teatreicall experice</p>
                <img className="desc-img" src={yacht_luxury_90ft_bedroom} alt="90ft super premium luxury yacht bedroom in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_luxury_90ft_bedroom} alt="90ft super premium luxury yacht bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>5. We provide free cancellation only for customers who book online</p>
                <p style={{marginTop: '2%'}}>6. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>
                <p style={{marginTop: '2%'}}>7. All taxes are included in the price, so there are no additional costs.Dont worry about additional cost</p>
                <p style={{marginTop: '2%'}}>8. In addition, you can enjoy water sports such as donut rides, banana rides, jetski and more</p>
                <img className="desc-img" src={yacht_luxury_90ft_balcony} alt="90ft super premium luxury yacht portico in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_luxury_90ft_balcony} alt="90ft super premium luxury yacht portico in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>9. All these luxury experiences at a low price. Book now!</p>




            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>

    <script src = "script.js"></script>
    <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
</div>

);
}

export default YachtLuxury90ft;