import React from 'react';
import { Helmet } from 'react-helmet';
import './style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

function ContactUs() {
  return (

<div style={{marginTop : '5%'}}>

<Helmet>
<title>Contact Our Dubai Yacht Events | Save upto 40%</title>
<meta name="description" content="Contact our Dubai yacht events team for luxury yacht experiences and exclusive private yachts and boats to your needs. Save up to 10% by booking with us. "/>
<meta name="keywords" content="contact dubai yacht events, book dubai yacht, book dubai boat, contact yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/contact_us"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Contact",
        "item": "https://dubaiyachtevents.com/contact_us"
      }
    ]
  })}
  </script>




     
     </Helmet>

<Header/>
<ScrollArrows />

	<div class="container" id="container">
		<main class="row">
			

			<section class="col left">
				

				<div class="contactTitle">
					<h2>Get In Touch</h2>
					<p>Can't find the right option? Still have doubts? Contact us! We will clarify all your doubts and help you choose the right option.</p>
				</div>



				<div class="contactInfo">
					
					<div class="iconGroup">
						<div class="icon">
							<i class="fa-solid fa-phone"></i>
						</div>
						<div class="details">
							<span>Phone</span>
							<span>+971545619773</span>
						</div>
					</div>

					<div class="iconGroup">
						<div class="icon">
							<i class="fa-solid fa-envelope"></i>
						</div>
						<div class="details">
							<span>Email</span>
							<span>booking@boatrentaldubai.com</span>
						</div>
					</div>

					<div class="iconGroup">
						<div class="icon">
							<i class="fa-solid fa-location-dot"></i>
						</div>
						<div class="details">
							<span>Location</span>
							<span>Berth BD21, Dubai Marina,<br/> Harbour, Dubai, United Arab Emirates</span>
						</div>
					</div>

				</div>



				<div class="socialMedia">
					<Link to="https://m.facebook.com/vcyachtsdubai/"><i class="fa-brands fa-facebook-f"></i></Link>
					<Link to="https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09"><i class="fa-brands fa-twitter"></i></Link>
					<Link to="https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg=="><i class="fa-brands fa-instagram"></i></Link>
					<Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20can%20we%20finalize%20our%20deal?"><i class="fa-brands fa-whatsapp"></i></Link>
				</div>


			</section>



			<section class="col right">
				

				<form class="messageForm" id="messageForm">
					<div class="inputGroup halfWidth">
						<input type="text" id="userName" required="required"/>
						<label>Your Name</label>
					</div>
				
					<div class="inputGroup halfWidth">
						<input type="tel" id="phoneNumber" required="required"/>
						<label>Phone Number</label>
					</div>
				
					<div class="inputGroup fullWidth">
						<input type="text" id="subject" required="required"/>
						<label>Subject</label>
					</div>
				
					<div class="inputGroup fullWidth">
						<textarea id="message" required="required"></textarea>
						<label>Say Something</label>
					</div>
				
					<div class="inputGroup fullWidth">
						<button type="submit">Send Message</button>
					</div>
				</form>
				
				

			</section>

	

		</main>
	</div>
<section class="map-container">
	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1806.7566954767667!2d55.146924!3d25.084477!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b2674550b0f%3A0xb238d74bd52f625a!2sBoat%20Rental%20Dubai%20-%20Luxury%20Yacht%20in%20Dubai%20Marina!5e0!3m2!1sen!2sae!4v1718613590260!5m2!1sen!2sae" title='company location' width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="map"></iframe>
  </section>
  
	<Footer/>
	
	  
	 

</div>

);
}

export default ContactUs;