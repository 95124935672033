import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/boat_styles.css';
import { Link } from 'react-router-dom';
import Header from '../Header';
import favicon from '../assets/images/favicon.webp';
import ScrollArrows from '../ScrollArrows';
import boat_36ft from'../boat_assets/images/36ft-boat-dubai-dubai-marina-boat-rental/36ft-boat-dubai-dubai-marina-boat-rental.webp';
import boat_36ft_bedroom from'../boat_assets/images/36ft-boat-dubai-dubai-marina-boat-rental/36-ft-reel-magic-boat-bedroom.webp';
import boat_36ft_balcony from'../boat_assets/images/36ft-boat-dubai-dubai-marina-boat-rental/36-ft-reel-magic-boat-portico.webp';
import boat_36ft_kitchen from'../boat_assets/images/36ft-boat-dubai-dubai-marina-boat-rental/36-ft-reel-magic-boat-kitchen.webp';
import boat_36ft_restroom from'../boat_assets/images/36ft-boat-dubai-dubai-marina-boat-rental/36-ft-reel-magic-boat-restroom.webp';
import BoatImgShowcase from './BoatImageShowcase';
import Footer from '../Footer';



function Boat36ft() {
    return (
    
        <div className='body'>

     <Helmet>

     <title>36Ft Boat In Dubai Marina @349 AED</title>
     <meta name="description" content="Check out the 36 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!"/>
     <meta name="keywords" content="book dubai boat, 36ft boat dubai, mini boat dubai, small boat dubai, dubai marina boat, boat rental dubai, dubai boat ride" />
     <link rel="canonical" href="https://dubaiyachtevents.com/boat_36ft"/>
     <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebPage",
            "@id": "https://dubaiyachtevents.com/boat_36ft",
            "url": "https://dubaiyachtevents.com/boat_36ft",
            "name": "36Ft Reel Magic Boat In Dubai Marina @349 AED",
            "description": "Check out the 36 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!",
            "publisher": {
              "@type": "Organization",
              "name": "Dubai Yacht Events",
              "logo": {
                "@type": "ImageObject",
                "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
              },
              "url": "https://dubaiyachtevents.com/",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+971545619773",
                "contactType": "Customer Service",
                "areaServed": ["EN", "AE", "HI", "ML", "TA"]
              }
            }
          },
          {
            "@type": "WebSite",
            "name": "Dubai Yacht Events",
            "alternateName": "Dubai Yacht Ride",
            "url": "https://dubaiyachtevents.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
              },
              "query-input": "required name=search_term_string"
            }
          },
          {
            "@type": "Product",
            "name": "36Ft Boat Dubai-Dubai Marina Boat Rental",
            "description": "Check out the 36 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!",
            "sku": "YACHT002",
            "brand": {
              "@type": "Brand",
              "name": "Dubai Yacht Events"
            },
            "offers": {
              "@type": "Offer",
              "price": "349.00",
              "priceCurrency": "AED",
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/InStock",
              "url": "https://dubaiyachtevents.com/boat_36ft",
              "seller": {
                "@type": "Organization",
                "name": "Dubai Yacht Events"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "reviewCount": "5589"
            },
            "image": [
              "https://dubaiyachtevents.com/static/media/36ft-boat-dubai-dubai-marina-boat-rental.bcd91b1f3d32f70b73f0.webp",
              "https://dubaiyachtevents.com/static/media/36-ft-reel-magic-boat-bedroom.fbd7824675cc7782fe32.webp",
              "https://dubaiyachtevents.com/static/media/36-ft-reel-magic-boat-portico.09bf8ec0becc1de5eb3e.webp",
              "https://dubaiyachtevents.com/static/media/36-ft-reel-magic-boat-kitchen.5c102a985b47838cf0fe.webp",
              "https://dubaiyachtevents.com/static/media/36-ft-reel-magic-boat-restroom.2a41dbce7ed283444a6f.webp"
            ]
          },
          {
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "How to rent 36ft boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
                }
              },
              {
                "@type": "Question",
                "name": "Where i can ride this 36 ft boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "This 36ft boat is available in dubai marina.You can start your ride from dubai marina."
                }
              },
              {
                "@type": "Question",
                "name": "Is this 36 ft boat is an speed boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes 36ft boat in dubai is an speed boat."
                }
              },
              {
                "@type": "Question",
                "name": "How to book 36ft speed boat in dubai marina?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "dubaiyachtevents.com is the best website to book speed boat in Dubai with 0% commission."
                }
              },
              {
                "@type": "Question",
                "name": "What is the price for the 36ft speed boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "the price for the speed boat in dubai is approximately AED 349"
                }
              }
            ]
          },
          {
            "@type": "Place",
            "name": "Dubai Marina",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            }
          },
          {
            "@type": "LocalBusiness",
            "name": "Dubai Yacht Events",
            "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            },
            "url": "https://dubaiyachtevents.com/",
            "telephone": "+971545619773",
            "priceRange": "349 AED",
            "sameAs": [
            "https://m.facebook.com/vcyachtsdubai/",
            "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
            "https://pin.it/2CuYXWb",
            "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
            "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
            "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
            "https://boatrentaldubaimarina.blogspot.com/"
            ],
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              }
            ]
          }
        ]
      })}
      </script>
  <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "home",
        "item": "https://dubaiyachtevents.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "36ft boat",
        "item": "https://dubaiyachtevents.com/boat_36ft"
      }
    ]
  })}
  </script>



     
     </Helmet>

        <Header/>

        <ScrollArrows />
        
        <div className = "boat-card-wrapper">
          <div className = "boat-card">
                <BoatImgShowcase
              boat_1={boat_36ft}
              boat_2={boat_36ft_bedroom}
              boat_3={boat_36ft_balcony}
              boat_4={boat_36ft_kitchen}
              boat_5={boat_36ft_restroom}
              boat_1_alt="36ft boat in dubai"
              boat_2_alt="36ft boat restroom in dubai"
              boat_3_alt="36ft boat bedroom in dubai"
              boat_4_alt="36ft boat kitchen in dubai"
              boat_5_alt="36ft boat portico in dubai"
            />
            <div className = "boat-product-content">
              <h2 className = "boat-product-title">36Ft Boat Dubai</h2>
              <Link to = "#" className = "boat-product-link">Visit Dubai</Link>
              <div className = "boat-product-rating">
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star-half-alt"></i>
                <span>4.9(10254)</span>
              </div>
    
              <div className = "boat-product-price">
                <p className = "boat-last-price">Old Price: <span>AED 420.00</span></p>
                <p className = "boat-new-price">New Price: <span><b>AED 399.00 (5%)</b></span></p>
              </div>
    
              <div className = "boat-product-detail">
                <h2>About This Boat: </h2>
                <p>The 36Ft Boat Dubai-Dubai Marina Boat Rental is a stylish. Its speed is really impressive as it flies through the water at a very high speed</p>
                <ul>
                  <li>Name: <span><b>36Ft Boat Dubai-Dubai Marina Boat Rental</b></span></li>
                  <li>Hours: <span><b>Flexible Hours</b></span></li>
                  <li>Category: <span><b>Dubai Boat</b></span></li>
                  <li>Location: <span><b>Dubai Marina</b></span></li>
                  <li>PLaces: <span><b>Al over Dubai</b></span></li>
                  <li>Includes: <span><b>Juice,Fishing Equipments etc</b></span></li>
                  <li>Add ons: <span><b>Food,Watersports etc</b></span></li>
                </ul>
              </div>
    
              <div className = "boat-purchase-info">
                
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2036ft%20Reel%20Magic%20boat.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">
                  Watsapp
                </Link>
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2036ft%20Reel%20Magic%20boat.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">Rent Now</Link>
              </div>
    
              <div className = "boat-social-links">
                <p>Share At: </p>
                <Link to = "#">
                  <i className = "fab fa-facebook-f"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-twitter"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-instagram"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-whatsapp"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-pinterest"></i>
                </Link>
              </div>
              
            </div>
            <div className='boat-details'>

            <h2>Description</h2><br/>
            <p>The 36Ft Boat Dubai-Dubai Marina Boat Rental is a modern, stylish boat. It looks very stylish and fast <br/>
            Maximum <strong>10 peoples</strong> can ride in this boat. <br/>
            At AED 399 price, we provide life jacket for all passengers. <br/>
            For all 10 people, we provide cool drinks. <br/>
            All the above are included with the price.
          </p>
          </div>

          </div>
        </div>
    
     
      <Footer/>
    
    

      </div>
);

}

export default Boat36ft;